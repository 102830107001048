const translationsObject = {
  en: {
    for_page: "For This Page",
    for_mobile: "For This Page through mobile",
    followus_alt: "Follow Us on %s",
    media_alt: {
      Facebook: "Facebook",
      Twitter: "X - Twitter",
      Linkedin: "Linkedin",
      Weibo: "Weibo",
      Wechat: "Wechat",
      Youtube: "Youtube",
      Youku: "Youku",
    },
    share_email: "Share %devType% on Email ",
    share_whatsapp: "Share %devType% on Whatsapp",
    share_fb_msg: "Share %devType% on Facebook Messager ",
    share_wechat: "Share %devType% on Wechat ",
    share_fb: "Share %devType% on Facebook ",
    share_twitter: "Share %devType% on Twitter ",
    share_lkd: "Share %devType% on Linkedin ",
    share_weibo: "Share %devType% on Weibo ",
    pause_btn: "Pause / Play Button",
    main_logo_alt: "Hong Kong Development Council logo",
    change_lang: "Change Language",
    mobile_share: "Share for Mobile",
    mobile_menu: "Menu for Mobile",
    header_search_submit_btn_txt: "Header Search Submit",
    event_search_submit_btn_txt: "Search Events Submit",
    source_search_submit_btn_txt: "Search Products / Services Submit",
    search_submit_btn_txt: "Search Submit",
    search_clear_btn_txt: "Clear Input",
    hidden_button_label: "go detials",
    hidden_button_announcement_close_title: "Close Announcement Bar",
    hidden_button_announcement_close_label: "Close",
    hidden_source_search_bar_label: "Enter Keywords At Source Page",
    slider_button_next: "Next Slider",
    slider_button_prev: "Previous Slider",
    txt_month: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    txt_weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dateFormat: {
      full: "D MMM YYYY HH:mm:ss",
      long: "D MMM YYYY",
      short: "MMM YYYY",
      YYYYMMMD: "D MMM YYYY",
      YYYYMMM: "MMM YYYY",
      MMMD: "D MMM",
      YYYYMMMD_c: "D MMM YYYY",
      MMM: "MMM",
      D: "D",
    },
    pagenotfound: {
      title: "Page Not Found",
      desc: "We're sorry, we couldn't find the page you requested.",
    },
    lang: {
      title_en: "Languages",
      title_tc: "語言",
      title_sc: "语言",
      en: {
        txt_short: "en",
        txt_long: "English",
        locale: "en",
      },
      tc: {
        txt_short: "繁",
        txt_long: "繁體中文",
        locale: "zh-hk",
      },
      sc: {
        txt_short: "简",
        txt_long: "简体中文",
        locale: "zh-cn",
      },
    },
    topBar: {
      quick_links: [
        {
          txt: "About HKTDC",
          href: "http://aboutus.hktdc.com/en/",
        },
        {
          txt: "Media Room",
          href: "http://mediaroom.hktdc.com/",
        },
      ],
      title_login: "Login",
      title_logout: "Logout",
      title_edit_profile: "Edit Profile",
      title_register: "Register",
      title_myhktdc: "My HKTDC",
      txt_back: "Back",
    },
    events: {
      tableHeading: "Event Schedule",
      introContent: {
        tdcevents: "HKTDC Events",
        hkevents: "More Events in Hong Kong",
        osevents: "More Events Outside Hong Kong",
        serviceTradeEvents: "Promoting Hong Kong Services",
        merchandiseTradeEvents: "Promoting Hong Kong Products",
        tagline: "Your Business • Our Mission",
        btn: "See more events",
        tabAll: "All",
        tabHK: "Hong Kong",
        tabOS: "Outside Hong Kong",
        tabPast: "Past Events",
        tabsTitle: {
          all: "All HKTDC Events",
          hk: "HKTDC Events in Hong Kong",
          outsidehk: "HKTDC Events outside Hong Kong",
          pastevent: "Past Events",
        },
      },
      upcomingEvents: {
        title: "Upcoming Events",
      },
      moreEvents: {
        tdcevents: [
          {
            cat: "hkevents",
            title: "More Events in Hong Kong",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "More Events Outside Hong Kong",
            href: "pagename_outsideevents",
          },
        ],
        hkevents: [
          {
            cat: "tdcevents",
            title: "HKTDC Events",
            href: "pagename_events",
          },
          {
            cat: "osevents",
            title: "More Events Outside Hong Kong",
            href: "pagename_outsideevents",
          },
        ],
        osevents: [
          {
            cat: "tdcevents",
            title: "HKTDC Events",
            href: "pagename_events",
          },
          {
            cat: "hkevents",
            title: "More Events in Hong Kong",
            href: "pagename_hkevents",
          },
        ],
        serviceTradeEvents: [
          {
            cat: "hkevents",
            title: "More Events in Hong Kong",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "More Events Outside Hong Kong",
            href: "pagename_outsideevents",
          },
        ],
        merchandiseTradeEvents: [
          {
            cat: "hkevents",
            title: "More Events in Hong Kong",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "More Events Outside Hong Kong",
            href: "pagename_outsideevents",
          },
        ],
      },
      filter: {
        industries: {
          title: "Industry",
          options: {
            "Accounting-Services": {
              queryStr: "Accounting Services",
              displayStr: "Accounting Services",
            },
            "Advertising-Services": {
              queryStr: "Advertising Services",
              displayStr: "Advertising Services",
            },
            "Agriculture-and-Forestry": {
              queryStr: "Agriculture and Forestry",
              displayStr: "Agriculture and Forestry",
            },
            "Architecture-Planning": {
              queryStr: "Architecture & Planning",
              displayStr: "Architecture & Planning",
            },
            "Association-Service-Government-Organisation": {
              queryStr: "Association Service & Government Organisation",
              displayStr: "Association Service & Government Organisation",
            },
            "Auto-Parts-Accessories": {
              queryStr: "Auto Parts & Accessories",
              displayStr: "Auto Parts & Accessories",
            },
            "Baby-Products": {
              queryStr: "Baby Products",
              displayStr: "Baby Products",
            },
            "Banking-Services": {
              queryStr: "Banking Services",
              displayStr: "Banking Services",
            },
            "Beauty-Services": {
              queryStr: "Beauty Services",
              displayStr: "Beauty Services",
            },
            "Books-Printed-Items": {
              queryStr: "Books & Printed Items",
              displayStr: "Books & Printed Items",
            },
            "Building-Construction-Services": {
              queryStr: "Building & Construction Services",
              displayStr: "Building & Construction Services",
            },
            "Building-Materials": {
              queryStr: "Building Materials",
              displayStr: "Building Materials",
            },
            "Business-Management-Consultancy-Services": {
              queryStr: "Business Management & Consultancy Services",
              displayStr: "Business Management & Consultancy Services",
            },
            "Catering-Services": {
              queryStr: "Catering Services",
              displayStr: "Catering Services",
            },
            Chemicals: { queryStr: "Chemicals", displayStr: "Chemicals" },
            "Computer-Peripherals": {
              queryStr: "Computer & Peripherals",
              displayStr: "Computer & Peripherals",
            },
            "Design-Services": {
              queryStr: "Design Services",
              displayStr: "Design Services",
            },
            "Education-Training": {
              queryStr: "Education & Training",
              displayStr: "Education & Training",
            },
            "Electronics-Electrical-Appliances": {
              queryStr: "Electronics & Electrical Appliances",
              displayStr: "Electronics & Electrical Appliances",
            },
            Energy: { queryStr: "Energy", displayStr: "Energy" },
            "Engineering-Services": {
              queryStr: "Engineering Services",
              displayStr: "Engineering Services",
            },
            "Environmental-Protection": {
              queryStr: "Environmental Protection",
              displayStr: "Environmental Protection",
            },
            "Environmental-Services": {
              queryStr: "Environmental Services",
              displayStr: "Environmental Services",
            },
            "Event-Organization": {
              queryStr: "Event Organization",
              displayStr: "Event Organization",
            },
            "Eyewear-Accessories": {
              queryStr: "Eyewear & Accessories",
              displayStr: "Eyewear & Accessories",
            },
            "Film-Audio-Visual-Production": {
              queryStr: "Film / Audio-Visual Production",
              displayStr: "Film / Audio-Visual Production",
            },
            "Finance-Investment": {
              queryStr: "Finance & Investment",
              displayStr: "Finance & Investment",
            },
            "Food-Beverages": {
              queryStr: "Food & Beverages",
              displayStr: "Food & Beverages",
            },
            Footwear: { queryStr: "Footwear", displayStr: "Footwear" },
            "Furniture-Furnishings": {
              queryStr: "Furniture & Furnishings",
              displayStr: "Furniture & Furnishings",
            },
            "Garments-Textiles-Accessories": {
              queryStr: "Garments, Textiles & Accessories",
              displayStr: "Garments, Textiles & Accessories",
            },
            "Gifts-Premiums": {
              queryStr: "Gifts & Premiums",
              displayStr: "Gifts & Premiums",
            },
            "Handbags-Travel-Goods": {
              queryStr: "Handbags & Travel Goods",
              displayStr: "Handbags & Travel Goods",
            },
            Hardware: { queryStr: "Hardware", displayStr: "Hardware" },
            "Health-Beauty": {
              queryStr: "Health & Beauty",
              displayStr: "Health & Beauty",
            },
            "Household-Products": {
              queryStr: "Household Products",
              displayStr: "Household Products",
            },
            "Information-Technology-Services": {
              queryStr: "Information Technology Services",
              displayStr: "Information Technology Services",
            },
            Infrastructure: {
              queryStr: "Infrastructure",
              displayStr: "Infrastructure",
            },
            Insurance: { queryStr: "Insurance", displayStr: "Insurance" },
            "Intellectual-Property": {
              queryStr: "Intellectual Property",
              displayStr: "Intellectual Property",
            },
            "Interior-Design-Services": {
              queryStr: "Interior Design Services",
              displayStr: "Interior Design Services",
            },
            Jewellery: { queryStr: "Jewellery", displayStr: "Jewellery" },
            "Legal-Services": {
              queryStr: "Legal Services",
              displayStr: "Legal Services",
            },
            Licensing: { queryStr: "Licensing", displayStr: "Licensing" },
            "Lighting-Products": {
              queryStr: "Lighting Products",
              displayStr: "Lighting Products",
            },
            "Logistics-Transport-Services": {
              queryStr: "Logistics & Transport Services",
              displayStr: "Logistics & Transport Services",
            },
            Machinery: { queryStr: "Machinery", displayStr: "Machinery" },
            "Manufacturing-and-Relocation": {
              queryStr: "Manufacturing and Relocation",
              displayStr: "Manufacturing and Relocation",
            },
            Media: { queryStr: "Media", displayStr: "Media" },
            "Medical-Healthcare-Services": {
              queryStr: "Medical & Healthcare Services",
              displayStr: "Medical & Healthcare Services",
            },
            "Medical-Supplies-Medicine": {
              queryStr: "Medical Supplies & Medicine",
              displayStr: "Medical Supplies & Medicine",
            },
            Others: { queryStr: "Others", displayStr: "Others" },
            "Packaging-Materials": {
              queryStr: "Packaging Materials",
              displayStr: "Packaging Materials",
            },
            "Packaging-Services": {
              queryStr: "Packaging Services",
              displayStr: "Packaging Services",
            },
            "Pet-Pet-Supplies": {
              queryStr: "Pet & Pet Supplies",
              displayStr: "Pet & Pet Supplies",
            },
            "Photographic-Equipment": {
              queryStr: "Photographic Equipment",
              displayStr: "Photographic Equipment",
            },
            "Professional-Services": {
              queryStr: "Professional Services",
              displayStr: "Professional Services",
            },
            "Printing-Services": {
              queryStr: "Printing Services",
              displayStr: "Printing Services",
            },
            "Public-Relations": {
              queryStr: "Public Relations",
              displayStr: "Public Relations",
            },
            "Publishing-Services": {
              queryStr: "Publishing Services",
              displayStr: "Publishing Services",
            },
            "Quality-Inspection-and-Testing": {
              queryStr: "Quality Inspection and Testing",
              displayStr: "Quality Inspection and Testing",
            },
            "Raw-Materials": {
              queryStr: "Raw Materials",
              displayStr: "Raw Materials",
            },
            "Real-Estate-Services": {
              queryStr: "Real Estate Services",
              displayStr: "Real Estate Services",
            },
            "Specialised-Products": {
              queryStr: "Specialised Products",
              displayStr: "Specialised Products",
            },
            "Sports-Goods": {
              queryStr: "Sports Goods",
              displayStr: "Sports Goods",
            },
            "Stationery-Office-Equipment": {
              queryStr: "Stationery & Office Equipment",
              displayStr: "Stationery & Office Equipment",
            },
            "Storage-Services": {
              queryStr: "Storage Services",
              displayStr: "Storage Services",
            },
            Surveying: { queryStr: "Surveying", displayStr: "Surveying" },
            Technology: { queryStr: "Technology", displayStr: "Technology" },
            "Telecommunication-Services": {
              queryStr: "Telecommunication Services",
              displayStr: "Telecommunication Services",
            },
            Telecommunications: {
              queryStr: "Telecommunications",
              displayStr: "Telecommunications",
            },
            "Tourism-(Travel)": {
              queryStr: "Tourism (Travel)",
              displayStr: "Tourism (Travel)",
            },
            "Toys-Games": {
              queryStr: "Toys & Games",
              displayStr: "Toys & Games",
            },
            Trade: { queryStr: "Trade", displayStr: "Trade" },
            "Watches-Clocks": {
              queryStr: "Watches & Clocks",
              displayStr: "Watches & Clocks",
            },
          },
        },
        eventFormat: {
          title: "Event Format",
          options: {
            ConferenceForum: "Conference & Forum",
            Exhibition: "Exhibition",
            HongKongPavilion: "Hong Kong Pavilion",
            SeminarWorkshop: "Seminar & Workshop",
            TradeMission: "Trade Mission",
            Others: "Others",
          },
        },
        countryRegion: {
          title: "Country / Region",
          options: {
            Africa: { queryStr: "Africa", displayStr: "Africa" },
            ASEAN: { queryStr: "ASEAN", displayStr: "ASEAN" },
            Australasia: { queryStr: "Australasia", displayStr: "Australasia" },
            "Central-South-America": {
              queryStr: "Central & South America",
              displayStr: "Central & South America",
            },
            "Mainland-China": {
              queryStr: "Mainland China, China",
              displayStr: "Mainland China",
            },
            "Eastern-Europe": {
              queryStr: "Eastern Europe",
              displayStr: "Eastern Europe",
            },
            EU: { queryStr: "EU", displayStr: "EU" },
            "Hong-Kong": { queryStr: "Hong Kong", displayStr: "Hong Kong" },
            Japan: { queryStr: "Japan", displayStr: "Japan" },
            Korea: { queryStr: "Korea", displayStr: "Korea" },
            "Middle-East": {
              queryStr: "Middle East",
              displayStr: "Middle East",
            },
            "North-America": {
              queryStr: "North America",
              displayStr: "North America",
            },
            "Other-Asian-Countries": {
              queryStr: "Other Asian Countries",
              displayStr: "Other Asian Countries",
            },
            Scandinavia: { queryStr: "Scandinavia", displayStr: "Scandinavia" },
            "Southeast-Asia": {
              queryStr: "Southeast Asia",
              displayStr: "Southeast Asia",
            },
            Taiwan: { queryStr: "Taiwan", displayStr: "Taiwan" },
            "Western-Europe": {
              queryStr: "Western Europe",
              displayStr: "Western Europe",
            },
            Worldwide: { queryStr: "Worldwide", displayStr: "Worldwide" },
          },
        },
        year: {
          title: "Year",
          options: ["2018", "2017", "2016", "2015", "2014", "2013"],
        },
      },
      eventFormat_map: {
        HongKongPavilion: ["Hong Kong Pavilion"],
        TradeMission: ["Outbound Mission"],
        SeminarWorkshop: ["Seminar", "Workshop"],
        ConferenceForum: ["Conference", "Forum"],
        Exhibition: ["Exhibition"],
        Others: [
          "Award",
          "Business Matching",
          "Catalogue Show",
          "Central Product Display",
          "Competition",
          "Dinner",
          "Fashion Show",
          "Inbound Mission",
          "Store Promotion",
          "Local Consumer Fair",
          "Meeting",
          "Networking",
          "Panel display",
          "Promotional Stand",
          "Road Show",
          "Round Table",
          "Showcase Display",
          "Special Events",
          "Reception",
          "Lunch",
          "Consumer Promotion",
          "Gala Evening",
          "Bilateral Committee",
          "Publicity Program",
          "Cocktail Reception",
          "Business Advisory Service",
          "Focus Discussion",
          "Guided Tour",
          "Traders Meet",
          "Training Course in HK",
          "Training Course outside HK",
          "Others",
        ],
      },
      txt_noresult: "Sorry! There are no matches.",
      txt_searchHints: "Input Event Name",
      eventListHeader: [
        "",
        "Date",
        "Event",
        "Event Format",
        "Country / Region",
      ],
      eventListHeader_others: [
        "Date",
        "Event",
        "Event Format",
        "Country / Region",
        "Organiser",
      ],
      txt_filterBy: "Filter By",
      txt_apply: "Apply Booth",
      txt_register: "Pre-Register",
      txt_exhList: "Exhibitor List",
      txt_fairCat: "Fair Catalogue",
      txt_enquire: "Enquire",
      txt_addToCalendar: "Add to Calendar",
      txt_download: "Download Form",
      txt_downloadForm: "Download Form",
      txt_publishVisitors: "Public Visitors",
      txt_eventVideo: "Watch Video",
    },
    navLinks: {
      business: {
        big_title: "Start",
        small_title: " & Grow Your Business",
      },
      events: {
        big_title: "Join",
        small_title: " Fairs, Conferences & Events",
        links: [
          "All HKTDC Events",
          "HKTDC Events in Hong Kong",
          "HKTDC Events outside Hong Kong",
          "Past Events",
        ],
      },
      services: {
        big_title: "Source",
        small_title: " Products & Services",
        introImgUrl:
          "http://www.hktdc.com/suppliers/china-wholesale-suppliers/en",
      },
      insights: {
        big_title: "Explore",
        small_title: " Industry & Market Insights",
      },
    },
    title_textsize: "Text Size",
    textsize: [
      {
        title: "How to Change Text Size",
        desc: "Now with this responsive web design, you can easily change the text size to fit your eyesight needs.",
      },
      {
        title: "Shortcut",
        desc: "If you browse on HKTDC website on a desktop computer, a modern web browser will allow you to hold down the Ctrl key (Command key on a Macintosh) and press the + or - key to increase or decrease the text size, and 0 to go back to the default size.<br/><br/>On a smartphone or a tablet, you can simply pinch open on the screen to zoom in (i.e. to enlarge) and pinch close to zoom out (i.e. to reduce) everything.",
      },
      {
        title: "Menu",
        desc: "You may also change the text size from the menu. Choose from the following web browsers for specific instructions:",
      },
      {
        subtitle: "Chrome",
        desc: "On the Page menu, select Zoom, then Larger.",
      },
      {
        subtitle: "Firefox",
        desc: "On the View menu, select Zoom, then Zoom In.<br/><br/>Or you may click on the Menu icon and select + or - to make the text size bigger or smaller.",
      },
      {
        subtitle: "Internet Explorer / Edge",
        desc: "On the View menu, select Text Size, then Largest.<br/><br/>Or you may click on the IE Settings icon, select Zoom, then choose a percentage or click Zoom In.",
      },
      {
        subtitle: "Opera",
        desc: "On the View menu, select Zoom. Then choose a percentage.",
      },
      {
        subtitle: "Safari",
        desc: "On the View menu, select Make Text Bigger.",
      },
      {
        desc: "<i>Endorsement Disclaimer<br/>HKTDC does not endorse specific web browsers or recommend one browser over another. While some popular browsers are included here, mention of a specific browser does not imply any endorsement or recommendation.</i>",
      },
    ],
    title_marketFocus: "Market Focus",
    title_quicklinks: "Quick Links",
    title_service_quicklinks: "Quick Links",
    title_service_moreevents: "More Events",
    title_service_upcoming: "Upcoming",
    title_successStories: "Success Stories",
    title_mobileapp: "HKTDC Mobile Apps",
    title_help: "How Can We Help?",
    title_share: "Share",
    share_wechat: '"Scan QR Code" in WeChat and tap "..." to share.',
    youtube_link: "https://www.youtube.com/user/HKTDC",
    btnMore: "More",
    btnDetail: "details",
    btnLearnMore: "Learn More",
    btnDownload: "Download:",
    mainpage_channelBar: {
      appIcon: "/images/home/icon-hktdc.png",
      appTitle: "HKTDC Mobile Apps",
      appDesc:
        "Whether you seek market information, want to do business through our online marketplace or take part in our international conferences and exhibitions, the HKTDC's mobile apps can help. Download them free of charge from App Store or Google Play.",
      appBtnTxt: "More",
      shareTxt: "Follow Us",
    },
    btnReadMore: "Read More",
    btnServicesReadMore: "Read More",
    subscribeNow: {
      title: "Free Business Information",
      desc: "Subscribe to keep pace with global developments",
      href: "https://hkmb.hktdc.com/en/enewsletter/introduction",
      btnTxt: "Sign Up",
    },
    footerBottom: {
      links: [
        {
          txt: "Text Size",
          href: "s/textsize",
        },
        {
          txt: "About HKTDC",
          href: "https://aboutus.hktdc.com/en/",
        },
        {
          txt: "Contact HKTDC",
          href: "https://home.hktdc.com/en/contact-us/?source_url=",
          is_contact: true,
        },
        {
          txt: "HKTDC Mobile Apps",
          href: "https://home.hktdc.com/en/mobile-apps",
        },
        {
          txt: "Hong Kong Means Business eNewsletter",
          href: "https://hkmb.hktdc.com/en/enewsletter/introduction",
        },
        {
          txt: "E-subscription preferences",
          href: "https://www.hktdc.com/epc/epc_subscription_request.htm",
        },
      ],
      tncLinks: [
        {
          txt: "Terms of Use",
          href: "https://home.hktdc.com/en/s/terms-of-use",
        },
        {
          txt: "Privacy Statement",
          href: "https://home.hktdc.com/en/s/privacy-policy-statement",
        },
        {
          txt: "Hyperlink Policy",
          href: "https://home.hktdc.com/en/s/hyperlink-policy",
        },
        {
          txt: "Web Accessibility",
          href: "s/web-accessibility",
        },
        {
          txt: "Sitemap",
          href: "s/sitemap",
        },
        {
          txt: "京ICP备09059244号",
          href: "http://www.miibeian.gov.cn",
        },
        {
          txt: "京公网安备 11010102002019号",
          href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102003523",
        },
      ],
      title_follow: "Follow Us",
      logo: "/images/footer/img-logo-with-tag.svg",
      logoMobile: "/images/footer/img-logo-with-tag-mb.svg",
      copyRightTitle: "Copyright © %CURRENTYEAR%",
      copyRight: "Hong Kong Trade Development Council. All rights reserved.",
      title_awards: "Awards received:",
    },
    searchTxt: "Search",
    searchCharErrorTxt:
      "Please enter a valid keyword with 2 or more characters / numbers.",
    searchCloseTxt: "Close",
    searchHints: "Enter Keywords",
    searchRibbonExhibitor: "HKTDC<br/>Exhibitor",
    searchProduct: " Products",
    searchService: " Services",
    searchSupplier: " Suppliers",
    searchHints_suppliers: "Search Products",
    searchPreferredListingTxt: "Preferred Listing",
    searchPanel: {
      txt_result: "Result",
      txt_results: "Results",
      txt_keywords: "Keywords",
      txt_sorting: "Sort by",
      txt_sorting_relevancy: "Relevancy",
      txt_sorting_latest: "Latest",
      txt_sorting_oldest: "Oldest",
      txt_sorting_asc: "Ascending",
      txt_sorting_desc: "Descending",
      txt_no_result: "Sorry! No records are found for ",
      txt_no_suggest: "Did you mean",
      txt_enter_keywords: "Please enter a keyword",
    },
    searchResult: {
      txt_view_more_result_suppliers:
        "View more %KEYWORD% results from suppliers",
      txt_contact_us: "Contact Us",
      txt_buy_now: "Buy Now",
      txt_get_latest_price: "Get Latest Price",
      txt_min_order: "Min. Order: %STRING%",
      txt_ref_price: "Reference Price: %STRING%",
      txt_pro_leadTime: "Production Lead Time : %STRING% day(s)",
      alt_meetus: "Click to find out where you can meet this supplier",
      alt_soz: "Products available for small order",
      txt_no_title: "Untitled",
      txt_next: "Next",
      txt_prev: "Previous",
      txt_applyboothdownloadform: "Apply Booth / Download Form",
      txt_register: "Pre-Register",
      txt_exhList: "Exhibitor List",
      txt_fairCat: "Fair Catalogue",
      txt_enquire: "Enquire",
      txt_addToCalendar: "Add to Calendar",
      txt_productServiceRange: "Product/Service Range: ",
      txt_publishVisitors: "Public Visitors",
      txt_eventVideo: "Watch Video",
    },
    sriInfo: {
      CVS: "Certificates Verified Service focuses on the verification of the certificates that corporations obtained in the areas of Corporate Social Responsibility, Product Management, In-House Laboratory Management.",
      DNB: "D&B Checked service verifies suppliers' information through D & B 's Authentication & Verification procedure twice a year. A globally recognized DUNS Number will be given to suppliers upon successful verification, and a D&B Checked icon will be shown on suppliers' advertising page on hktdc.com to demonstrate the integrity.",
      EXH: "Credible exhibitors at HKTDC world-class trade fairs. Find more details on supplier' s participation in HKTDC activities including exhibiting history and status at past and upcoming HKTDC fairs.",
      PREMIUM:
        "Credible suppliers advertising at hktdc.com. Find more company information including product highlights, factory details, trading activities, R&D and ODM/OED capability, as well as factory tours and product videos.",
      PREMIUM1:
        "Credible suppliers who have advertised at hktdc.com for the first year. Find more company information including product highlights, factory details, trading activities, R&D and ODM/OED capability, as well as factory tours and product videos.",
      PREMIUM2:
        "Credible suppliers who have advertised consecutively at hktdc.com for the second year. Find more company information including product highlights, factory details, trading activities, R&D and ODM/OED capability, as well as factory tours and product videos.",
      PREMIUM3:
        "Credible suppliers who have advertised consecutively at hktdc.com for the third year. Find more company information including product highlights, factory details, trading activities, R&D and ODM/OED capability, as well as factory tours and product videos.",
      PREMIUM4:
        "Credible suppliers who have advertised consecutively at hktdc.com for the forth year. Find more company information including product highlights, factory details, trading activities, R&D and ODM/OED capability, as well as factory tours and product videos.",
      PREMIUM5:
        "Credible suppliers who have advertised consecutively at hktdc.com for the fifth year. Find more company information including product highlights, factory details, trading activities, R&D and ODM/OED capability, as well as factory tours and product videos.",
      PREMIUM5P:
        "Credible suppliers who have advertised consecutively at hktdc.com for over five years. Find more company information including product highlights, factory details, trading activities, R&D and ODM/OED capability, as well as factory tours and product videos.",
      SRI: "Some facts and figures about this supplier are available from third party organisations or HKTDC",
      ENV_COMPLIANCE: "Compliance Verified",
      QUALITY_COMPLIANCE: "Compliance Verified",
      SECURITY_COMPLIANCE: "Compliance Verified",
      SOCIAL_COMPLIANCE: "Compliance Verified",
      FREELISTING:
        "Information of this supplier have not been authenticated and verified",
    },
    topSearchTxt: "Top Search",
    searchTypeName: {
      all: "All",
      "exhibitions-conferences": "Exhibitions & Conferences",
      suppliers: "Products & Services",
      "market-insights": "Market Insights",
      others: "Others",
    },
    successStories: {
      filter: {
        industries: "Industry",
        regions: "Country / Region",
        themes: "Topic",
      },
    },
    cookiesBar: {
      show: "true",
      desc: "This site uses cookies to offer you a better browsing experience. Find out more on <a href='#'>How we use cookies</a>",
      btn_accept: {
        txt: "Accept",
      },
      btn_refuse: {
        txt: "Refuse",
      },
    },
    feedbackPanel: {
      title: "What do you think of the new site?",
      desc: '<p>The HKTDC website has a new look! Please let us know <a href="%TELLUS%" target="_blank">your views or suggestions</a> so that we can serve you better. During the soft launch period, you can still access the previous <a href="%DESKTOP%">desktop</a> or <a href="%MOBILE%">mobile</a> versions if you wish.</p>',
    },
    txt_publishDate: "Publish Date:",
    txt_loading: "Loading...",
    txt_error: "Error: Content could not be feteched.",
    factsheet: {
      date: "Date",
      countryRegion: "Country/Region",
      city: "City",
      venue: "Venue",
      address: "Address",
      locationMap: "Location Map",
      format: "Format",
      industry: "Industry",
      officialWebsiteUrl: "Official Website",
      topic: "Topic",
      speaker: "Speaker",
      participant: "Participant",
      participationFee: "Participation Fee",
      admissionFee: "Admission Fee",
      remark: "Remark",
      eventConductedLanguages: "Event Conducted Languages",
      organiser: "Organiser",
      coOrganiser: "Co-Organiser",
      supportingOrganisation: "Supporting Organisation",
      sponsor: "Sponsor",
      informationForDownload: "Information for Download",
      contactInformation: "Contact Information",
      button: {
        enquire: "Enquire",
        exhibitorList: "Exhibitor List",
        downloadForm: "Download Form",
        applyBooth: "Apply Booth",
        preRegister: "Pre-register",
      },
      eventDescription: "Event Description",
    },
    webcast: {
      industry: "Industry",
      region: "Region",
      speaker: "Speaker",
      keyword: "Keyword",
      relatedLinks: "Related Links",
      relatedPrograms: "Related Videos",
    },
    podcast: {
      industry: "Industry",
      region: "Region",
      speaker: "Speaker",
      keyword: "Keyword",
      relatedLinks: "Related Links",
      relatedPrograms: "Related Podcasts",
    },
    streamingLang: {
      hu: 'HUN',
      jp: 'JPN',
      es: 'SPA',
      en: 'ENG',
      can: 'YUE',
      ru: 'RUS',
      de: 'GER',
      ko: 'KOR',
      it: 'ITA',
      cs: 'CZE',
      pt: 'POR',
      pth: 'CHN',
      ar: 'ARA',
      fr: 'FRE'
    }
  },
  tc: {
    for_page: "這個頁面",
    for_mobile: "這個頁面在手机瀏覽時",
    followus_alt: "在 %s 關注我們",
    media_alt: {
      Facebook: "Facebook",
      Twitter: "X - Twitter",
      Linkedin: "Linkedin",
      Weibo: "Weibo",
      Wechat: "Wechat",
      Youtube: "Youtube",
      Youku: "Youku",
    },
    share_email: "分享%devType%到電郵",
    share_whatsapp: "分享%devType%到Whatsapp",
    share_fb_msg: "分享%devType%到Facebook Messager",
    share_wechat: "分享%devType%到Wechat",
    share_fb: "分享%devType%到Facebook",
    share_twitter: "分享%devType%到Twitter",
    share_lkd: "分享%devType%到Linkedin",
    share_weibo: "分享%devType%到Weibo",
    pause_btn: "暫停 / 播放 按鈕",
    main_logo_alt: "香港貿易發展局標誌",
    change_lang: "切換語言",
    mobile_share: "手機分享",
    mobile_menu: "手機菜單",
    header_search_submit_btn_txt: "頁首搜索提交",
    event_search_submit_btn_txt: "搜索活動提交",
    source_search_submit_btn_txt: "搜索產品/服務提交",
    search_submit_btn_txt: "搜索提交",
    search_clear_btn_txt: "清空輸入",
    hidden_button_label: "瀏覽詳情",
    hidden_button_announcement_close_title: "關閉公佈欄",
    hidden_button_announcement_close_label: "關閉",
    hidden_source_search_bar_label: "請在採購頁面輸入關鍵字",
    slider_button_next: "下一張",
    slider_button_prev: "上一張",
    txt_month: [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月",
    ],
    txt_weekdays: ["日", "一", "二", "三", "四", "五", "六"],
    dateFormat: {
      full: "YYYY年M月D日 HH:mm:ss",
      long: "YYYY年M月D日",
      short: "YYYY年M月",
      YYYYMMMD: "YYYY年M月D日",
      YYYYMMM: "YYYY年M月",
      MMMD: "M月D日",
      YYYYMMMD_c: "YYYY年M月D",
      MMM: "M月",
      D: "D日",
    },
    pagenotfound: {
      title: "找不到網頁",
      desc: "很抱歉，您尋找的網頁暫時未能顯示。",
    },
    lang: {
      title_en: "Languages",
      title_tc: "語言",
      title_sc: "语言",
      en: {
        txt_short: "en",
        txt_long: "English",
        locale: "en",
      },
      tc: {
        txt_short: "繁",
        txt_long: "繁體中文",
        locale: "zh-hk",
      },
      sc: {
        txt_short: "简",
        txt_long: "简体中文",
        locale: "zh-cn",
      },
    },
    topBar: {
      quick_links: [
        {
          txt: "關於香港貿發局",
          href: "http://aboutus.hktdc.com/tc/",
        },
        {
          txt: "新聞中心",
          href: "http://mediaroom.hktdc.com/tc",
        },
      ],
      title_login: "登入",
      title_logout: "登出",
      title_edit_profile: "編輯用戶資料",
      title_register: "註冊",
      title_myhktdc: "我的HKTDC",
      txt_back: "返回",
    },
    events: {
      tableHeading: "活動時間表",
      introContent: {
        tdcevents: "香港貿發局活動",
        hkevents: "更多香港活動",
        osevents: "更多香港以外活動",
        serviceTradeEvents: "推廣香港服務業",
        merchandiseTradeEvents: "推廣香港製造業",
        tagline: "為您創商機",
        btn: "瀏覽更多活動",
        tabAll: "所有活動",
        tabHK: "香港活動",
        tabOS: "香港以外活動",
        tabPast: "過去活動",
        tabsTitle: {
          all: "所有貿發局活動",
          hk: "香港的貿發局活動",
          outsidehk: "香港以外的貿發局活動",
          pastevent: "過去的貿發局活動",
        },
      },
      upcomingEvents: {
        title: "即將舉行的活動",
      },
      moreEvents: {
        tdcevents: [
          {
            cat: "hkevents",
            title: "更多香港活動",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "更多香港以外活動",
            href: "pagename_outsideevents",
          },
        ],
        hkevents: [
          {
            cat: "tdcevents",
            title: "香港貿發局活動",
            href: "pagename_events",
          },
          {
            cat: "osevents",
            title: "更多香港以外活動",
            href: "pagename_outsideevents",
          },
        ],
        osevents: [
          {
            cat: "tdcevents",
            title: "香港貿發局活動",
            href: "pagename_events",
          },
          {
            cat: "hkevents",
            title: "更多香港活動",
            href: "pagename_hkevents",
          },
        ],
        serviceTradeEvents: [
          {
            cat: "hkevents",
            title: "更多香港活動",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "更多香港以外活動",
            href: "pagename_outsideevents",
          },
        ],
        merchandiseTradeEvents: [
          {
            cat: "hkevents",
            title: "更多香港活動",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "更多香港以外活動",
            href: "pagename_outsideevents",
          },
        ],
      },
      filter: {
        industries: {
          title: "行業",
          options: {
            "Accounting-Services": {
              queryStr: "會計服務",
              displayStr: "會計服務",
            },
            "Advertising-Services": {
              queryStr: "廣告服務",
              displayStr: "廣告服務",
            },
            "Agriculture-and-Forestry": {
              queryStr: "農業及林業",
              displayStr: "農業及林業",
            },
            "Architecture-Planning": {
              queryStr: "建築及規劃",
              displayStr: "建築及規劃",
            },
            "Association-Service-Government-Organisation": {
              queryStr: "商會服務及政府機構",
              displayStr: "商會服務及政府機構",
            },
            "Auto-Parts-Accessories": {
              queryStr: "汽車及零部件",
              displayStr: "汽車及零部件",
            },
            "Baby-Products": { queryStr: "嬰兒產品", displayStr: "嬰兒產品" },
            "Banking-Services": {
              queryStr: "銀行服務",
              displayStr: "銀行服務",
            },
            "Beauty-Services": { queryStr: "美容服務", displayStr: "美容服務" },
            "Books-Printed-Items": {
              queryStr: "書刊及印刷品",
              displayStr: "書刊及印刷品",
            },
            "Building-Construction-Services": {
              queryStr: "建造服務",
              displayStr: "建造服務",
            },
            "Building-Materials": {
              queryStr: "建築材料",
              displayStr: "建築材料",
            },
            "Business-Management-Consultancy-Services": {
              queryStr: "商業管理及顧問服務",
              displayStr: "商業管理及顧問服務",
            },
            "Catering-Services": {
              queryStr: "餐飲服務",
              displayStr: "餐飲服務",
            },
            Chemicals: { queryStr: "化學品", displayStr: "化學品" },
            "Computer-Peripherals": {
              queryStr: "電腦及週邊設備",
              displayStr: "電腦及週邊設備",
            },
            "Design-Services": { queryStr: "設計服務", displayStr: "設計服務" },
            "Education-Training": {
              queryStr: "教育及培訓",
              displayStr: "教育及培訓",
            },
            "Electronics-Electrical-Appliances": {
              queryStr: "電子產品及電器",
              displayStr: "電子產品及電器",
            },
            Energy: { queryStr: "能源", displayStr: "能源" },
            "Engineering-Services": {
              queryStr: "工程服務",
              displayStr: "工程服務",
            },
            "Environmental-Protection": {
              queryStr: "環保用品",
              displayStr: "環保用品",
            },
            "Environmental-Services": {
              queryStr: "環保服務",
              displayStr: "環保服務",
            },
            "Event-Organization": {
              queryStr: "活動籌劃",
              displayStr: "活動籌劃",
            },
            "Eyewear-Accessories": {
              queryStr: "眼鏡及配件",
              displayStr: "眼鏡及配件",
            },
            "Film-Audio-Visual-Production": {
              queryStr: "電影 / 影音製作",
              displayStr: "電影 / 影音製作",
            },
            "Finance-Investment": {
              queryStr: "金融及投資",
              displayStr: "金融及投資",
            },
            "Food-Beverages": {
              queryStr: "食品及飲料",
              displayStr: "食品及飲料",
            },
            Footwear: { queryStr: "鞋類", displayStr: "鞋類" },
            "Furniture-Furnishings": {
              queryStr: "家具及布置用品",
              displayStr: "家具及布置用品",
            },
            "Garments-Textiles-Accessories": {
              queryStr: "成衣、紡織及配件",
              displayStr: "成衣、紡織及配件",
            },
            "Gifts-Premiums": {
              queryStr: "禮品及贈品",
              displayStr: "禮品及贈品",
            },
            "Handbags-Travel-Goods": {
              queryStr: "手袋及旅行用品",
              displayStr: "手袋及旅行用品",
            },
            Hardware: { queryStr: "五金", displayStr: "五金" },
            "Health-Beauty": {
              queryStr: "健康及美容產品",
              displayStr: "健康及美容產品",
            },
            "Household-Products": {
              queryStr: "家庭用品",
              displayStr: "家庭用品",
            },
            "Information-Technology-Services": {
              queryStr: "資訊科技服務",
              displayStr: "資訊科技服務",
            },
            Infrastructure: { queryStr: "基建", displayStr: "基建" },
            Insurance: { queryStr: "保險", displayStr: "保險" },
            "Intellectual-Property": {
              queryStr: "知識產權",
              displayStr: "知識產權",
            },
            "Interior-Design-Services": {
              queryStr: "室內設計服務",
              displayStr: "室內設計服務",
            },
            Jewellery: { queryStr: "珠寶", displayStr: "珠寶" },
            "Legal-Services": { queryStr: "法律服務", displayStr: "法律服務" },
            Licensing: { queryStr: "專利授權", displayStr: "專利授權" },
            "Lighting-Products": {
              queryStr: "照明產品",
              displayStr: "照明產品",
            },
            "Logistics-Transport-Services": {
              queryStr: "物流管理及運輸服務",
              displayStr: "物流管理及運輸服務",
            },
            Machinery: { queryStr: "機械", displayStr: "機械" },
            "Manufacturing-and-Relocation": {
              queryStr: "製造及產業轉移",
              displayStr: "製造及產業轉移",
            },
            Media: { queryStr: "傳媒", displayStr: "傳媒" },
            "Medical-Healthcare-Services": {
              queryStr: "醫療及保健服務",
              displayStr: "醫療及保健服務",
            },
            "Medical-Supplies-Medicine": {
              queryStr: "醫療用品及醫藥",
              displayStr: "醫療用品及醫藥",
            },
            Others: { queryStr: "其他", displayStr: "其他" },
            "Packaging-Materials": {
              queryStr: "包裝材料",
              displayStr: "包裝材料",
            },
            "Packaging-Services": {
              queryStr: "包裝服務",
              displayStr: "包裝服務",
            },
            "Pet-Pet-Supplies": {
              queryStr: "寵物及寵物用品",
              displayStr: "寵物及寵物用品",
            },
            "Photographic-Equipment": {
              queryStr: "攝影器材",
              displayStr: "攝影器材",
            },
            "Professional-Services": {
              queryStr: "專業服務",
              displayStr: "專業服務",
            },
            "Printing-Services": {
              queryStr: "印刷服務",
              displayStr: "印刷服務",
            },
            "Public-Relations": {
              queryStr: "公共關係",
              displayStr: "公共關係",
            },
            "Publishing-Services": {
              queryStr: "出版服務",
              displayStr: "出版服務",
            },
            "Quality-Inspection-and-Testing": {
              queryStr: "品質檢查及測試",
              displayStr: "品質檢查及測試",
            },
            "Raw-Materials": { queryStr: "原材料", displayStr: "原材料" },
            "Real-Estate-Services": {
              queryStr: "房地產服務",
              displayStr: "房地產服務",
            },
            "Specialised-Products": {
              queryStr: "專業產品",
              displayStr: "專業產品",
            },
            "Sports-Goods": { queryStr: "運動用品", displayStr: "運動用品" },
            "Stationery-Office-Equipment": {
              queryStr: "文具及辦公室設備用品",
              displayStr: "文具及辦公室設備用品",
            },
            "Storage-Services": {
              queryStr: "倉庫服務",
              displayStr: "倉庫服務",
            },
            Surveying: { queryStr: "測量", displayStr: "測量" },
            Technology: { queryStr: "科技", displayStr: "科技" },
            "Telecommunication-Services": {
              queryStr: "電訊服務",
              displayStr: "電訊服務",
            },
            Telecommunications: { queryStr: "電訊", displayStr: "電訊" },
            "Tourism-(Travel)": { queryStr: "旅遊", displayStr: "旅遊" },
            "Toys-Games": { queryStr: "玩具及遊戲", displayStr: "玩具及遊戲" },
            Trade: { queryStr: "貿易", displayStr: "貿易" },
            "Watches-Clocks": { queryStr: "鐘錶", displayStr: "鐘錶" },
          },
        },
        eventFormat: {
          title: "活動形式",
          options: {
            ConferenceForum: "會議及論壇",
            Exhibition: "展覽",
            HongKongPavilion: "香港館",
            SeminarWorkshop: "研討會及工作坊",
            TradeMission: "商貿代表團",
            Others: "其他",
          },
        },
        countryRegion: {
          title: "國家 / 地區",
          options: {
            Africa: { queryStr: "非洲", displayStr: "非洲" },
            ASEAN: { queryStr: "東盟", displayStr: "東盟" },
            Australasia: { queryStr: "大洋洲", displayStr: "大洋洲" },
            "Central-South-America": {
              queryStr: "中南美洲",
              displayStr: "中南美洲",
            },
            "Mainland-China": {
              queryStr: "中國, 中國內地",
              displayStr: "中國內地",
            },
            "Eastern-Europe": { queryStr: "東歐", displayStr: "東歐" },
            EU: { queryStr: "歐盟", displayStr: "歐盟" },
            "Hong-Kong": { queryStr: "香港", displayStr: "香港" },
            Japan: { queryStr: "日本", displayStr: "日本" },
            Korea: { queryStr: "韓國", displayStr: "韓國" },
            "Middle-East": { queryStr: "中東", displayStr: "中東" },
            "North-America": { queryStr: "北美洲", displayStr: "北美洲" },
            "Other-Asian-Countries": {
              queryStr: "其他亞洲國家",
              displayStr: "其他亞洲國家",
            },
            Scandinavia: {
              queryStr: "斯幹那維亞半島",
              displayStr: "斯幹那維亞半島",
            },
            "Southeast-Asia": { queryStr: "東南亞", displayStr: "東南亞" },
            Taiwan: { queryStr: "台灣,臺灣", displayStr: "台灣" }, //PHR-773, support both Tai Wan word
            "Western-Europe": { queryStr: "西歐", displayStr: "西歐" },
            Worldwide: { queryStr: "環球", displayStr: "環球" },
          },
        },
        year: {
          title: "年份",
          options: ["2018", "2017", "2016", "2015", "2014", "2013"],
        },
      },
      eventFormat_map: {
        HongKongPavilion: ["香港館"],
        TradeMission: ["外訪團"],
        SeminarWorkshop: ["研討會", "工作坊"],
        ConferenceForum: ["會議", "論壇"],
        Exhibition: ["展覽會"],
        Others: [
          "頒獎禮",
          "商貿配對活動",
          "產品目錄展示",
          "產品綜合展示",
          "比賽",
          "晚宴",
          "時裝表演",
          "訪港團",
          "百貨公司展銷會",
          "香港零售展覽會",
          "會議",
          "交流活動",
          "產品展示",
          "宣傳展台",
          "路演",
          "圓桌會議",
          "產品展示",
          "特別活動",
          "招待會",
          "午宴",
          "公眾推廣活動",
          "慶祝晚會",
          "雙邊委員會",
          "宣傳活動",
          "酒會",
          "商貿諮詢服務",
          "專題座談會 ",
          "導賞服務",
          "「商」聚一「會」 ",
          "香港培訓班",
          "海外培訓班",
          "其它",
        ],
      },
      txt_noresult: "對不起，找不到符合的資料！",
      txt_searchHints: "輸入活動名稱",
      eventListHeader: ["", "日期", "活動", "活動形式", "國家 / 地區"],
      eventListHeader_others: ["日期", "活動", "活動形式", "國家 / 地區", "主辦機構"],
      txt_filterBy: "篩選",
      txt_apply: "申請展位",
      txt_register: "預先登記",
      txt_exhList: "參展商名單",
      txt_fairCat: "展覽會場刊",
      txt_enquire: "查詢",
      txt_addToCalendar: "加入行事曆",
      txt_download: "下載表格",
      txt_downloadForm: "下載表格",
      txt_publishVisitors: "公眾人士資訊",
      txt_eventVideo: "觀看影片",
    },
    navLinks: {
      business: {
        big_title: "開拓",
        small_title: "業務",
      },
      events: {
        big_title: "參與",
        small_title: "會議及展覽活動",
        links: [
          "香港貿發局活動",
          "香港的貿發局活動",
          "香港以外的貿發局活動",
          "過去的貿發局活動",
        ],
      },
      services: {
        big_title: "採購",
        small_title: "產品及服務",
        introImgUrl:
          "http://www.hktdc.com/suppliers/china-wholesale-suppliers/tc",
      },
      insights: {
        big_title: "掌握",
        small_title: "行業及市場資訊",
      },
    },
    title_textsize: "字體大小",
    textsize: [
      {
        title: "如何變更網頁字體大小",
        desc: "採用了適應性網頁設計後，你可簡易地變更字型大小，配合個人需要。",
      },
      {
        title: "快速鍵",
        desc: "假如你使用桌上型電腦瀏覽「香港貿發局」網頁，近代的瀏覽器可讓用家按著Ctrl鍵（在Macintosh鍵盤上是Command鍵）和+／-鍵來放大或縮小字型，而按著Ctrl鍵和0則可回復預設大小。<br/><br/>使用智能電話和平板電腦時，你只需在螢幕上滑動手指便能放大或縮小圖文。",
      },
      {
        title: "選項單",
        desc: "你也可透過選項單來變更字型大小。下列的網頁瀏覽器各有不同操作：",
      },
      {
        subtitle: "Chrome",
        desc: "在頁面的選項單選擇「縮放」，然後按「+」放大。",
      },
      {
        subtitle: "Firefox",
        desc: "在「檢視」一欄點選「縮放」，再選擇「放大」。<br/><br/>你也可在頁面的選項單按「+」或「-」來放大或縮小字型。",
      },
      {
        subtitle: "Internet Explorer / Edge",
        desc: "在「檢視」一欄點選「文字大小」，選擇「較大」或「最大」。<br/><br/>你也可按IE的設定標記點選「縮放」，再選擇合適的百分比或「放大」。",
      },
      {
        subtitle: "Opera",
        desc: "在「檢視」一欄點選「縮放」，再選擇合適的百分比。",
      },
      {
        subtitle: "Safari",
        desc: "在「檢視」一欄選擇「放大文字」。",
      },
      {
        desc: "<i>免責聲明<br/>「香港貿發局」並沒有認可特定的瀏覽器或建議使用哪一個瀏覽器。本文內容包括部分常用的瀏覽器，但提及某一個瀏覽器並沒有暗示任何認可或建議的意思。</i>",
      },
    ],
    title_marketFocus: "市場焦點",
    title_quicklinks: "了解更多",
    title_service_quicklinks: "快速連結",
    title_service_moreevents: "更多活動",
    title_service_upcoming: "即將舉行",
    title_successStories: "成功故事",
    title_mobileapp: "香港貿發局流動應用程式",
    title_help: "我們如何幫助您？",
    title_share: "分享",
    share_wechat: "請在微信中掃描二維碼然後按「...」以分享",
    youtube_link: "https://www.youtube.com/user/hktdctradchi",
    btnMore: "更多",
    btnDetail: "詳細內容",
    btnLearnMore: "了解更多",
    btnDownload: "下載:",
    mainpage_channelBar: {
      appIcon: "/images/home/icon-hktdc.png",
      appTitle: "香港貿發局流動應用程式",
      appDesc:
        "無論是要搜尋市場資訊，通過商貿平台做買賣，還是參與國際會議和展覽，香港貿發局的流動應用程式都可以幫到您。請即到App Store或Google Play免費下載。",
      appBtnTxt: "更多",
      shareTxt: "關注我們",
    },
    btnReadMore: "更多內容",
    btnServicesReadMore: "了解更多",
    subscribeNow: {
      title: "免費商貿資訊",
      desc: "立即訂閱，助你掌握環球營商動態",
      href: "https://hkmb.hktdc.com/tc/enewsletter/introduction",
      btnTxt: "登記",
    },
    footerBottom: {
      links: [
        {
          txt: "字體大小",
          href: "s/textsize",
        },
        {
          txt: "關於我們",
          href: "https://aboutus.hktdc.com/tc/",
        },
        {
          txt: "聯絡我們",
          href: "https://home.hktdc.com/tc/contact-us/?source_url=",
          is_contact: true,
        },
        {
          txt: "香港貿發局流動應用程式",
          href: "https://home.hktdc.com/tc/mobile-apps",
        },
        {
          txt: "訂閱商貿全接觸電郵通訊",
          href: "https://hkmb.hktdc.com/tc/enewsletter/introduction",
        },
        {
          txt: "更新您的香港貿發局電郵訂閱",
          href: "https://www.hktdc.com/epc/epc_subscription_request.htm?locale=zh_TW",
        },
      ],
      tncLinks: [
        {
          txt: "使用條款",
          href: "https://home.hktdc.com/tc/s/terms-of-use",
        },
        {
          txt: "私隱政策聲明",
          href: "https://home.hktdc.com/tc/s/privacy-policy-statement",
        },
        {
          txt: "超連結條款及細則",
          href: "https://home.hktdc.com/tc/s/hyperlink-policy",
        },
        {
          txt: "無障礙網頁",
          href: "s/web-accessibility",
        },
        {
          txt: "網站導航",
          href: "s/sitemap",
        },
        {
          txt: "京ICP备09059244号",
          href: "http://www.miibeian.gov.cn",
        },
        {
          txt: "京公网安备 11010102002019号",
          href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102003523",
        },
      ],
      title_follow: "關注我們",
      logo: "/images/footer/img-logo-with-tag-tc.svg",
      logoMobile: "/images/footer/img-logo-with-tag-tc-mb.svg",
      copyRightTitle: "© %CURRENTYEAR%",
      copyRight: "香港貿易發展局版權所有，對違反版權者保留一切追索權利 。",
      title_awards: "曾獲獎項︰",
    },
    searchTxt: "搜尋",
    searchCharErrorTxt: "請輸入最少兩個字母或數字的關鍵字。",
    searchCloseTxt: "關閉",
    searchHints: "輸入關鍵詞",
    searchRibbonExhibitor: "香港貿發局<br/>參展商",
    searchProduct: "產品",
    searchService: "服務",
    searchSupplier: "供應商",
    searchHints_suppliers: "搜索產品",
    searchPreferredListingTxt: "優先列表",
    searchPanel: {
      txt_result: "結果",
      txt_results: "結果",
      txt_keywords: "關鍵詞",
      txt_sorting: "排序",
      txt_sorting_relevancy: "相關",
      txt_sorting_latest: "最新",
      txt_sorting_oldest: "最舊",
      txt_sorting_asc: "最新",
      txt_sorting_desc: "最舊",
      txt_no_result: "抱歉! 沒有資料符合 ",
      txt_no_suggest: "你是否指",
      txt_enter_keywords: "請輸入關鍵字",
    },
    searchResult: {
      txt_view_more_result_suppliers: "從供應商查看與 %KEYWORD% 相關的產品",
      txt_contact_us: "聯絡供應商",
      txt_buy_now: "Buy Now",
      txt_get_latest_price: "獲取最新價格",
      txt_min_order: "最低訂購數量: %STRING%",
      txt_ref_price: "參考價格: %STRING%",
      txt_pro_leadTime: "生產所需時間 : %STRING% 日",
      alt_meetus: "",
      alt_soz: "",
      txt_no_title: "無標題",
      txt_next: "下一頁",
      txt_prev: "上一頁",
      txt_apply: "申請展位",
      txt_download: "下載表格",
      txt_applyboothdownloadform: "申請展位 / 下載表格",
      txt_register: "預先登記",
      txt_exhList: "參展商名單",
      txt_fairCat: "展覽會場刊",
      txt_enquire: "查詢",
      txt_addToCalendar: "加入行事曆",
      txt_productServiceRange: "產品/服務範圍﹕",
      txt_publishVisitors: "公眾人士資訊",
      txt_eventVideo: "觀看影片",
    },
    sriInfo: {
      CVS: "證書認證服務為供應商核實公司資料,確定廠商質素和實力,核實範圍包括企業社會責任、管理系統、產品安全、產品測試、質量管制",
      DNB: "由鄧白氏商業資料(香港)有限公司提供，每年為供應商查核公司資料兩次，完成認證程序後，供應商可取得一個全球認可的鄧氏編碼，並連同鄧白氏認證標誌放在貿發網「採購指南」廣告網頁上，向潛在買家突顯公司誠信，增強競爭力。",
      EXH: "香港貿發局世界級展覽會的優質參展商。閣下可參考供應商參與香港貿發局活動的資料 , 包括於過去曾經參加之香港貿發局展覽會及在不久將來參加的展覽活動。",
      PREMIUM:
        "於 「貿發網」上刊登廣告的優質供應商。 閣下可參考有關供應商公司的詳盡數據及資料,包括產品推介、廠房資料、業務資料、 研究與開發、原件創造/原創設計生產能力,以及廠房實況和產品展示短片等。",
      PREMIUM1:
        "首年於 「貿發網」上刊登廣告的優質供應商。 閣下可參考有關供應商公司的詳盡數據及資料,包括產品推介、廠房資料、業務資料、 研究與開發、原件創造/原創設計生產能力,以及廠房實況和產品展示短片等。",
      PREMIUM2:
        "連續第2年於 「貿發網」上刊登廣告的優質供應商。 閣下可參考有關供應商公司的詳盡數據及資料,包括產品推介、廠房資料、業務資料、 研究與開發、原件創造/原創設計生產能力,以及廠房實況和產品展示短片等。",
      PREMIUM3:
        "連續第3年於 「貿發網」上刊登廣告的優質供應商。 閣下可參考有關供應商公司的詳盡數據及資料,包括產品推介、廠房資料、業務資料、 研究與開發、原件創造/原創設計生產能力,以及廠房實況和產品展示短片等。",
      PREMIUM4:
        "連續第4年於 「貿發網」上刊登廣告的優質供應商。 閣下可參考有關供應商公司的詳盡數據及資料,包括產品推介、廠房資料、業務資料、 研究與開發、原件創造/原創設計生產能力,以及廠房實況和產品展示短片等。",
      PREMIUM5:
        "連續第5年於 「貿發網」上刊登廣告的優質供應商。 閣下可參考有關供應商公司的詳盡數據及資料,包括產品推介、廠房資料、業務資料、 研究與開發、原件創造/原創設計生產能力,以及廠房實況和產品展示短片等。",
      PREMIUM5P:
        "連續五年以上於 「貿發網」上刊登廣告的優質供應商。 閣下可參考有關供應商公司的詳盡數據及資料,包括產品推介、廠房資料、業務資料、 研究與開發、原件創造/原創設計生產能力,以及廠房實況和產品展示短片等。",
      SRI: "由第三方或貿發局提供的數據及資料,以給買家作識別。",
      ENV_COMPLIANCE: "廠房符合性檢核",
      QUALITY_COMPLIANCE: "廠房符合性檢核",
      SECURITY_COMPLIANCE: "廠房符合性檢核",
      SOCIAL_COMPLIANCE: "廠房符合性檢核",
      FREELISTING: "這供應商的數據資料未經查核及認證。",
    },
    topSearchTxt: "熱門搜尋",
    searchTypeName: {
      all: "所有",
      "exhibitions-conferences": "會議及展覽",
      suppliers: "產品及服務",
      "market-insights": "市場訊息",
      others: "其他",
    },
    successStories: {
      filter: {
        industries: "行業",
        regions: "國家 / 地區",
        themes: "專題",
      },
    },
    cookiesBar: {
      show: "true",
      desc: "This site uses cookies to offer you a better browsing experience. Find out more on <a href='#'>How we use cookies</a>",
      btn_accept: {
        txt: "Accept",
      },
      btn_refuse: {
        txt: "Refuse",
      },
    },
    feedbackPanel: {
      title: "您對全新首頁有甚麼意見？",
      desc: '<p>香港貿發局網站換上了新裝！我們期待<a href="%TELLUS%" target="_blank">你的意見</a>，讓我們為你提供更佳的服務。在網站試行期間，你可隨時瀏覽舊版的<a href="%DESKTOP%">網頁版</a>或<a href="%MOBILE%">流動版</a>。</p>',
    },
    txt_publishDate: "發佈日期:",
    txt_loading: "載入中...",
    txt_error: "系統錯誤: 不能獲取内容。",
    factsheet: {
      date: "日期",
      countryRegion: "國家/地區",
      city: "城市",
      venue: "地點",
      address: "地址",
      locationMap: "位置圖",
      format: "形式",
      industry: "行業",
      officialWebsiteUrl: "官方網站",
      topic: "主題",
      speaker: "演講嘉賓",
      participant: "對象",
      participationFee: "費用",
      admissionFee: "入場費用",
      remark: "備注",
      eventConductedLanguages: "講授語言",
      organiser: "主辦機構",
      coOrganiser: "協辦機構",
      supportingOrganisation: "支持機構",
      sponsor: "贊助商",
      informationForDownload: "資料下載",
      contactInformation: "聯絡資料",
      button: {
        enquire: "查詢",
        exhibitorList: "參展商",
        downloadForm: "下載表格",
        applyBooth: "申請展位",
        preRegister: "預先登記",
      },
      eventDescription: "詳細資料",
    },
    webcast: {
      industry: "行業",
      region: "地區",
      speaker: "演說者",
      keyword: "關鍵字",
      relatedLinks: "相關資料",
      relatedPrograms: "相關影片",
    },
    podcast: {
      industry: "行業",
      region: "地區",
      speaker: "演說者",
      keyword: "關鍵字",
      relatedLinks: "相關資料",
      relatedPrograms: "相關語音廣播",
    },
    streamingLang: {
      hu: '匈牙利',
      jp: '日',
      es: '西班牙',
      en: '英',
      can: '粤',
      ru: '俄',
      de: '德',
      ko: '韓',
      it: '意大利',
      cs: '捷克',
      pt: '葡萄牙',
      pth: '普',
      ar: '阿拉伯',
      fr: '法'
    }
  },
  sc: {
    for_page: "这个页面",
    for_mobile: "这个页面在手机浏览时",
    followus_alt: "在 %s 关注我们",
    media_alt: {
      Facebook: "Facebook",
      Twitter: "X -Twitter",
      Linkedin: "Linkedin",
      Weibo: "Weibo",
      Wechat: "Wechat",
      Youtube: "Youtube",
      Youku: "Youku",
    },
    share_email: "分享%devType%到邮件",
    share_whatsapp: "分享%devType%到Whatsapp",
    share_fb_msg: "分享%devType%到Facebook Messager",
    share_wechat: "分享%devType%到Wechat",
    share_fb: "分享%devType%到Facebook",
    share_twitter: "分享%devType%到Twitter",
    share_lkd: "分享%devType%到Linkedin",
    share_weibo: "分享%devType%到Weibo",
    pause_btn: "暂停 / 播放 按钮",
    main_logo_alt: "香港贸易发展局标志",
    change_lang: "切换语言",
    mobile_share: "手机分享",
    mobile_menu: "手机菜单",
    header_search_submit_btn_txt: "页首搜索提交",
    event_search_submit_btn_txt: "搜索活动提交",
    source_search_submit_btn_txt: "搜索产品/服务提交",
    search_submit_btn_txt: "搜索提交",
    search_clear_btn_txt: "清空输入框",
    hidden_button_label: "浏览详情",
    hidden_button_announcement_close_title: "关闭公布栏",
    hidden_button_announcement_close_label: "关闭",
    hidden_source_search_bar_label: "请在采购页面输入关键词",
    slider_button_next: "下一张",
    slider_button_prev: "上一张",
    txt_month: [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月",
    ],
    txt_weekdays: ["日", "一", "二", "三", "四", "五", "六"],
    dateFormat: {
      full: "YYYY年M月D日 HH:mm:ss",
      long: "YYYY年M月D日",
      short: "YYYY年M月",
      YYYYMMMD: "YYYY年M月D日",
      YYYYMMM: "YYYY年M月",
      MMMD: "M月D日",
      YYYYMMMD_c: "YYYY年M月D",
      MMM: "M月",
      D: "D日",
    },
    pagenotfound: {
      title: "找不到网页",
      desc: "很抱歉，您寻找的网页暂时未能显示。",
    },
    lang: {
      title_en: "Languages",
      title_tc: "語言",
      title_sc: "语言",
      en: {
        txt_short: "en",
        txt_long: "English",
        locale: "en",
      },
      tc: {
        txt_short: "繁",
        txt_long: "繁體中文",
        locale: "zh-hk",
      },
      sc: {
        txt_short: "简",
        txt_long: "简体中文",
        locale: "zh-cn",
      },
    },
    topBar: {
      quick_links: [
        {
          txt: "关於香港贸发局",
          href: "http://aboutus.hktdc.com/sc/",
        },
        {
          txt: "新闻中心",
          href: "http://mediaroom.hktdc.com/sc",
        },
      ],
      title_login: "登入",
      title_logout: "登出",
      title_edit_profile: "编辑用户资料",
      title_register: "注册",
      title_myhktdc: "我的HKTDC",
      txt_back: "返回",
    },
    events: {
      tableHeading: "活动时间表",
      introContent: {
        tdcevents: "香港贸发局活动",
        hkevents: "更多香港活动",
        osevents: "更多香港以外活动",
        serviceTradeEvents: "推广香港服务业",
        merchandiseTradeEvents: "推广香港制造业",
        tagline: "为您创商机",
        btn: "浏览更多活动",
        tabAll: "所有活动",
        tabHK: "香港活动",
        tabOS: "香港以外活动",
        tabPast: "过去活动",
        tabsTitle: {
          all: "所有贸发局活动",
          hk: "香港的贸发局活动",
          outsidehk: "香港以外的贸发局活动",
          pastevent: "过去的贸发局活动",
        },
      },
      upcomingEvents: {
        title: "即将举行的活动",
      },
      moreEvents: {
        tdcevents: [
          {
            cat: "hkevents",
            title: "更多香港活动",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "更多香港以外活动",
            href: "pagename_outsideevents",
          },
        ],
        hkevents: [
          {
            cat: "tdcevents",
            title: "香港贸发局活动",
            href: "pagename_events",
          },
          {
            cat: "osevents",
            title: "更多香港以外活动",
            href: "pagename_outsideevents",
          },
        ],
        osevents: [
          {
            cat: "tdcevents",
            title: "香港贸发局活动",
            href: "pagename_events",
          },
          {
            cat: "hkevents",
            title: "更多香港活动",
            href: "pagename_hkevents",
          },
        ],
        serviceTradeEvents: [
          {
            cat: "hkevents",
            title: "更多香港活动",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "更多香港以外活动",
            href: "pagename_outsideevents",
          },
        ],
        merchandiseTradeEvents: [
          {
            cat: "hkevents",
            title: "更多香港活动",
            href: "pagename_hkevents",
          },
          {
            cat: "osevents",
            title: "更多香港以外活动",
            href: "pagename_outsideevents",
          },
        ],
      },
      filter: {
        industries: {
          title: "行业",
          options: {
            "Accounting-Services": {
              queryStr: "会计服务",
              displayStr: "会计服务",
            },
            "Advertising-Services": {
              queryStr: "广告服务",
              displayStr: "广告服务",
            },
            "Agriculture-and-Forestry": {
              queryStr: "农业及林业",
              displayStr: "农业及林业",
            },
            "Architecture-Planning": {
              queryStr: "建筑及规划",
              displayStr: "建筑及规划",
            },
            "Association-Service-Government-Organisation": {
              queryStr: "商会服务及政府机构",
              displayStr: "商会服务及政府机构",
            },
            "Auto-Parts-Accessories": {
              queryStr: "汽车及零部件",
              displayStr: "汽车及零部件",
            },
            "Baby-Products": { queryStr: "婴儿产品", displayStr: "婴儿产品" },
            "Banking-Services": {
              queryStr: "银行服务",
              displayStr: "银行服务",
            },
            "Beauty-Services": { queryStr: "美容服务", displayStr: "美容服务" },
            "Books-Printed-Items": {
              queryStr: "书刊及印刷品",
              displayStr: "书刊及印刷品",
            },
            "Building-Construction-Services": {
              queryStr: "建造服务",
              displayStr: "建造服务",
            },
            "Building-Materials": {
              queryStr: "建筑材料",
              displayStr: "建筑材料",
            },
            "Business-Management-Consultancy-Services": {
              queryStr: "商业管理及顾问服务",
              displayStr: "商业管理及顾问服务",
            },
            "Catering-Services": {
              queryStr: "餐饮服务",
              displayStr: "餐饮服务",
            },
            Chemicals: { queryStr: "化学品", displayStr: "化学品" },
            "Computer-Peripherals": {
              queryStr: "计算机及外围设备",
              displayStr: "计算机及外围设备",
            },
            "Design-Services": { queryStr: "设计服务", displayStr: "设计服务" },
            "Education-Training": {
              queryStr: "教育及培训",
              displayStr: "教育及培训",
            },
            "Electronics-Electrical-Appliances": {
              queryStr: "电子产品及电器",
              displayStr: "电子产品及电器",
            },
            Energy: { queryStr: "能源", displayStr: "能源" },
            "Engineering-Services": {
              queryStr: "工程服务",
              displayStr: "工程服务",
            },
            "Environmental-Protection": {
              queryStr: "环保用品",
              displayStr: "环保用品",
            },
            "Environmental-Services": {
              queryStr: "环保服务",
              displayStr: "环保服务",
            },
            "Event-Organization": {
              queryStr: "活动筹划",
              displayStr: "活动筹划",
            },
            "Eyewear-Accessories": {
              queryStr: "眼镜及配件",
              displayStr: "眼镜及配件",
            },
            "Film-Audio-Visual-Production": {
              queryStr: "电影 / 影音制作",
              displayStr: "电影 / 影音制作",
            },
            "Finance-Investment": {
              queryStr: "金融及投资",
              displayStr: "金融及投资",
            },
            "Food-Beverages": {
              queryStr: "食品及饮料",
              displayStr: "食品及饮料",
            },
            Footwear: { queryStr: "鞋类", displayStr: "鞋类" },
            "Furniture-Furnishings": {
              queryStr: "家具及布置用品",
              displayStr: "家具及布置用品",
            },
            "Garments-Textiles-Accessories": {
              queryStr: "成衣、纺织及配件",
              displayStr: "成衣、纺织及配件",
            },
            "Gifts-Premiums": {
              queryStr: "礼品及赠品",
              displayStr: "礼品及赠品",
            },
            "Handbags-Travel-Goods": {
              queryStr: "手袋及旅行用品",
              displayStr: "手袋及旅行用品",
            },
            Hardware: { queryStr: "五金", displayStr: "五金" },
            "Health-Beauty": {
              queryStr: "健康及美容产品",
              displayStr: "健康及美容产品",
            },
            "Household-Products": {
              queryStr: "家庭用品",
              displayStr: "家庭用品",
            },
            "Information-Technology-Services": {
              queryStr: "资讯科技服务",
              displayStr: "资讯科技服务",
            },
            Infrastructure: { queryStr: "基建", displayStr: "基建" },
            Insurance: { queryStr: "保险", displayStr: "保险" },
            "Intellectual-Property": {
              queryStr: "知识产权",
              displayStr: "知识产权",
            },
            "Interior-Design-Services": {
              queryStr: "室内设计服务",
              displayStr: "室内设计服务",
            },
            Jewellery: { queryStr: "珠宝", displayStr: "珠宝" },
            "Legal-Services": { queryStr: "法律服务", displayStr: "法律服务" },
            Licensing: { queryStr: "专利授权", displayStr: "专利授权" },
            "Lighting-Products": {
              queryStr: "照明产品",
              displayStr: "照明产品",
            },
            "Logistics-Transport-Services": {
              queryStr: "物流管理及运输服务",
              displayStr: "物流管理及运输服务",
            },
            Machinery: { queryStr: "机械", displayStr: "机械" },
            "Manufacturing-and-Relocation": {
              queryStr: "制造及产业转移",
              displayStr: "制造及产业转移",
            },
            Media: { queryStr: "传媒", displayStr: "传媒" },
            "Medical-Healthcare-Services": {
              queryStr: "医疗及保健服务",
              displayStr: "医疗及保健服务",
            },
            "Medical-Supplies-Medicine": {
              queryStr: "医疗用品及医药",
              displayStr: "医疗用品及医药",
            },
            Others: { queryStr: "其他", displayStr: "其他" },
            "Packaging-Materials": {
              queryStr: "包装材料",
              displayStr: "包装材料",
            },
            "Packaging-Services": {
              queryStr: "包装服务",
              displayStr: "包装服务",
            },
            "Pet-Pet-Supplies": {
              queryStr: "宠物及宠物用品",
              displayStr: "宠物及宠物用品",
            },
            "Photographic-Equipment": {
              queryStr: "摄影器材",
              displayStr: "摄影器材",
            },
            "Professional-Services": {
              queryStr: "专业服务",
              displayStr: "专业服务",
            },
            "Printing-Services": {
              queryStr: "印刷服务",
              displayStr: "印刷服务",
            },
            "Public-Relations": {
              queryStr: "公共关系",
              displayStr: "公共关系",
            },
            "Publishing-Services": {
              queryStr: "出版服务",
              displayStr: "出版服务",
            },
            "Quality-Inspection-and-Testing": {
              queryStr: "质量检查及测试",
              displayStr: "质量检查及测试",
            },
            "Raw-Materials": { queryStr: "原材料", displayStr: "原材料" },
            "Real-Estate-Services": {
              queryStr: "房地产服务",
              displayStr: "房地产服务",
            },
            "Specialised-Products": {
              queryStr: "专业产品",
              displayStr: "专业产品",
            },
            "Sports-Goods": { queryStr: "运动用品", displayStr: "运动用品" },
            "Stationery-Office-Equipment": {
              queryStr: "文具及办公室设备用品",
              displayStr: "文具及办公室设备用品",
            },
            "Storage-Services": {
              queryStr: "仓库服务",
              displayStr: "仓库服务",
            },
            Surveying: { queryStr: "测量", displayStr: "测量" },
            Technology: { queryStr: "科技", displayStr: "科技" },
            "Telecommunication-Services": {
              queryStr: "电讯服务",
              displayStr: "电讯服务",
            },
            Telecommunications: { queryStr: "电讯", displayStr: "电讯" },
            "Tourism-(Travel)": { queryStr: "旅游", displayStr: "旅游" },
            "Toys-Games": { queryStr: "玩具及游戏", displayStr: "玩具及游戏" },
            Trade: { queryStr: "贸易", displayStr: "贸易" },
            "Watches-Clocks": { queryStr: "钟表", displayStr: "钟表" },
          },
        },
        eventFormat: {
          title: "活动形式",
          options: {
            ConferenceForum: "会议及论坛",
            Exhibition: "展览",
            HongKongPavilion: "香港馆",
            SeminarWorkshop: "研讨会及工作坊",
            TradeMission: "商贸代表团",
            Others: "其他",
          },
        },
        countryRegion: {
          title: "国家 / 地区",
          options: {
            Africa: { queryStr: "非洲", displayStr: "非洲" },
            ASEAN: { queryStr: "东盟", displayStr: "东盟" },
            Australasia: { queryStr: "大洋洲", displayStr: "大洋洲" },
            "Central-South-America": {
              queryStr: "中南美洲",
              displayStr: "中南美洲",
            },
            "Mainland-China": {
              queryStr: "中国, 中国内地",
              displayStr: "中国内地",
            },
            "Eastern-Europe": { queryStr: "东欧", displayStr: "东欧" },
            EU: { queryStr: "欧盟", displayStr: "欧盟" },
            "Hong-Kong": { queryStr: "香港", displayStr: "香港" },
            Japan: { queryStr: "日本", displayStr: "日本" },
            Korea: { queryStr: "韩国", displayStr: "韩国" },
            "Middle-East": { queryStr: "中东", displayStr: "中东" },
            "North-America": { queryStr: "北美洲", displayStr: "北美洲" },
            "Other-Asian-Countries": {
              queryStr: "其他亚洲国家",
              displayStr: "其他亚洲国家",
            },
            Scandinavia: {
              queryStr: "斯干那维亚半岛",
              displayStr: "斯干那维亚半岛",
            },
            "Southeast-Asia": { queryStr: "东南亚", displayStr: "东南亚" },
            Taiwan: { queryStr: "台湾", displayStr: "台湾" },
            "Western-Europe": { queryStr: "西欧", displayStr: "西欧" },
            Worldwide: { queryStr: "环球", displayStr: "环球" },
          },
        },
        year: {
          title: "年份",
        },
      },
      eventFormat_map: {
        HongKongPavilion: ["香港馆"],
        TradeMission: ["外访团"],
        SeminarWorkshop: ["研讨会", "工作坊"],
        ConferenceForum: ["会议", "论坛"],
        Exhibition: ["展览会"],
        Others: [
          "颁奖礼",
          "商贸配对活动",
          "产品目录展示",
          "产品综合展示",
          "比赛",
          "晚宴",
          "时装表演",
          "访港团",
          "百货公司展销会",
          "香港零售展览会",
          "会议",
          "交流活动",
          "产品展示",
          "宣传展台",
          "路演",
          "圆桌会议",
          "产品展示",
          "特别活动",
          "招待会",
          "午宴",
          "公众推广活动",
          "庆祝晚会",
          "双边委员会",
          "宣传活动",
          "酒会",
          "商贸咨询服务",
          "专题座谈会 ",
          "导赏服务",
          "「商」聚一「会」 ",
          "香港培训班",
          "海外培训班",
          "其它",
        ],
      },
      txt_noresult: "对不起，找不到符合的资料！",
      txt_searchHints: "输入活动名称",
      eventListHeader: ["", "日期", "活动", "活动形式", "国家 / 地区"],
      eventListHeader_others: [
        "日期",
        "活动",
        "活动形式",
        "国家 / 地区",
        "主办机构",
      ],
      txt_filterBy: "筛选",
      txt_apply: "申请展位",
      txt_register: "预先登记",
      txt_exhList: "参展商名单",
      txt_fairCat: "展览会场刊",
      txt_enquire: "查询",
      txt_addToCalendar: "加入行事历",
      txt_download: "下载表格",
      txt_downloadForm: "下载表格",
      txt_publishVisitors: "公众人士资讯",
      txt_eventVideo: "观看影片",
    },
    navLinks: {
      business: {
        big_title: "开拓",
        small_title: "业务",
      },
      events: {
        big_title: "参与",
        small_title: "会议及展览活动",
        links: [
          "香港贸发局活动",
          "香港的贸发局活动",
          "香港以外的贸发局活动",
          "过去的贸发局活动",
        ],
      },
      services: {
        big_title: "采购",
        small_title: "产品及服务",
        introImgUrl:
          "http://www.hktdc.com/suppliers/china-wholesale-suppliers/sc",
      },
      insights: {
        big_title: "掌握",
        small_title: "行业及市场讯息",
      },
    },
    title_textsize: "字体大小",
    textsize: [
      {
        title: "如何变更网页字体大小",
        desc: "采用了适应性网页设计後，你可简易地变更字型大小，配合个人需要。",
      },
      {
        title: "快速键",
        desc: "假如你使用桌上型电脑浏览「香港贸发局」网页，近代的浏览器可让用家按着Ctrl键（在Macintosh键盘上是Command键）和+／-键来放大或缩小字型，而按着Ctrl键和0则可回复预设大小。<br/><br/>使用智能电话和平板电脑时，你只需在萤幕上滑动手指便能放大或缩小图文。",
      },
      {
        title: "选项单",
        desc: "你也可透过选项单来变更字型大小。下列的网页浏览器各有不同操作：",
      },
      {
        subtitle: "Chrome",
        desc: "在页面的选项单选择「缩放」，然後按「+」放大。",
      },
      {
        subtitle: "Firefox",
        desc: "在「检视」一栏点选「缩放」，再选择「放大」。<br/><br/>你也可在页面的选项单按「+」或「-」来放大或缩小字型。",
      },
      {
        subtitle: "Internet Explorer / Edge",
        desc: "在「检视」一栏点选「文字大小」，选择「较大」或「最大」。<br/><br/>你也可按IE的设定标记点选「缩放」，再选择合适的百分比或「放大」。",
      },
      {
        subtitle: "Opera",
        desc: "在「检视」一栏点选「缩放」，再选择合适的百分比。",
      },
      {
        subtitle: "Safari",
        desc: "在「检视」一栏选择「放大文字」。",
      },
      {
        desc: "<i>免责声明<br/>「香港贸发局」并没有认可特定的浏览器或建议使用哪一个浏览器。本文内容包括部分常用的浏览器，但提及某一个浏览器并没有暗示任何认可或建议的意思。</i>",
      },
    ],
    title_marketFocus: "市场焦点",
    title_quicklinks: "了解更多",
    title_service_quicklinks: "快速连结",
    title_service_moreevents: "更多活动",
    title_service_upcoming: "即将举行",
    title_successStories: "成功故事",
    title_mobileapp: "香港贸发局流动应用程式",
    title_help: "我们如何帮助您？",
    title_share: "分享",
    share_wechat: "请在微信中扫描二维码然後按「...」以分享",
    youtube_link: "https://www.youtube.com/user/hktdcsimpchi",
    btnMore: "更多",
    btnDetail: "详细内容",
    btnLearnMore: "了解更多",
    btnDownload: "下载:",
    mainpage_channelBar: {
      appIcon: "/images/home/icon-hktdc.png",
      appTitle: "香港贸发局流动应用程式",
      appDesc:
        "无论是要搜寻市场资讯，通过商贸平台做买卖，还是参与国际会议和展览，香港贸发局的流动应用程式都可以帮到您。请即到App Store或Google Play免费下载。",
      appBtnTxt: "更多",
      shareTxt: "关注我们",
    },
    btnReadMore: "更多内容",
    btnServicesReadMore: "了解更多",
    subscribeNow: {
      title: "免费商贸资讯",
      desc: "立即订阅，助你掌握环球营商动态",
      href: "https://hkmb.hktdc.com/sc/enewsletter/introduction",
      btnTxt: "登记",
    },
    footerBottom: {
      links: [
        {
          txt: "字体大小",
          href: "s/textsize",
        },
        {
          txt: "关于我们",
          href: "https://aboutus.hktdc.com/sc/",
        },
        {
          txt: "联络我们",
          href: "https://home.hktdc.com/sc/contact-us/?source_url=",
          is_contact: true,
        },
        {
          txt: "香港贸发局流动应用程式",
          href: "https://home.hktdc.com/sc/mobile-apps",
        },
        {
          txt: "订阅商贸全接触电邮通讯",
          href: "https://hkmb.hktdc.com/sc/enewsletter/introduction",
        },
        {
          txt: "更新您的香港贸发局电邮订阅",
          href: "https://www.hktdc.com/epc/epc_subscription_request.htm?locale=zh_CN",
        },
      ],
      tncLinks: [
        {
          txt: "使用条款",
          href: "https://home.hktdc.com/sc/s/terms-of-use",
        },
        {
          txt: "私隐政策声明",
          href: "https://home.hktdc.com/sc/s/privacy-policy-statement",
        },
        {
          txt: "超连结条款及细则",
          href: "https://home.hktdc.com/sc/s/hyperlink-policy",
        },
        {
          txt: "无障碍网页",
          href: "s/web-accessibility",
        },
        {
          txt: "网站导航",
          href: "s/sitemap",
        },
        {
          txt: "京ICP备09059244号",
          href: "http://www.miibeian.gov.cn",
        },
        {
          txt: "京公网安备 11010102002019号",
          href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102003523",
        },
      ],
      title_follow: "关注我们",
      logo: "/images/footer/img-logo-with-tag-sc.svg",
      logoMobile: "/images/footer/img-logo-with-tag-sc-mb.svg",
      copyRightTitle: "© %CURRENTYEAR%",
      copyRight: "香港贸易发展局版权所有，对违反版权者保留一切追索权利 。",
      title_awards: "曾获奖项︰",
    },
    searchTxt: "搜寻",
    searchCharErrorTxt: "请输入最少两个字元或数字的关键字。",
    searchCloseTxt: "关闭",
    searchHints: "输入关键词",
    searchRibbonExhibitor: "香港贸发局<br/>参展商",
    searchProduct: "产品",
    searchService: "服务",
    searchSupplier: "供应商",
    searchHints_suppliers: "搜索产品",
    searchPreferredListingTxt: "优先列表",
    searchPanel: {
      txt_result: "结果",
      txt_results: "结果",
      txt_keywords: "关键词",
      txt_sorting: "排序",
      txt_sorting_relevancy: "相关",
      txt_sorting_latest: "最新",
      txt_sorting_oldest: "最旧",
      txt_sorting_asc: "最新",
      txt_sorting_desc: "最旧",
      txt_no_result: "抱歉! 没有资料符合 ",
      txt_no_suggest: "你是否指",
      txt_enter_keywords: "请输入关键字",
    },
    searchResult: {
      txt_view_more_result_suppliers: "从供应商查看与 %KEYWORD% 相关的产品",
      txt_contact_us: "联络供应商",
      txt_buy_now: "Buy Now",
      txt_get_latest_price: "获取最新价格",
      txt_min_order: "最低订购数量: %STRING%",
      txt_ref_price: "参考价格: %STRING%",
      txt_pro_leadTime: "生产所需时间: %STRING% 日",
      alt_meetus: "",
      alt_soz: "",
      txt_no_title: "无标题",
      txt_next: "下一页",
      txt_prev: "上一页",
      txt_apply: "申请展位",
      txt_download: "下载表格",
      txt_applyboothdownloadform: "申请展位 / 下载表格",
      txt_register: "预先登记",
      txt_exhList: "参展商名单",
      txt_fairCat: "展览会场刊",
      txt_enquire: "查询",
      txt_addToCalendar: "加入行事历",
      txt_productServiceRange: "产品/服务范围﹕",
      txt_publishVisitors: "公众人士资讯",
      txt_eventVideo: "观看影片",
    },
    sriInfo: {
      CVS: "证书认证服务为供货商核实公司资料,确定厂商质素和实力,核实范围包括企业社会责任、管理系统、产品安全、产品测试、质量管制",
      DNB: "由邓白氏商业资料(香港)有限公司提供，每年为供应商查核公司资料两次，完成认证程序后，供应商可取得一个全球认可的邓氏编码，并连同邓白氏认证标志放在贸发网「采购指南」广告网页上，向潜在买家突显公司诚信，增强竞争力。",
      EXH: "香港贸发局世界级展览会的优质参展商。阁下可参考供应商参与香港贸发局活动的资料 , 包括于过去曾经参加之香港贸发局展览会及在不久将来参加的展览活动。",
      PREMIUM:
        "于 「贸发网」上刊登广告的优质供应商。 阁下可参考有关供应商公司的详尽数据及资料,包括产品推介、厂房资料、业务资料、 研究与开发、原件创造/原创设计生产能力,以及厂房实况和产品展示短片等。",
      PREMIUM1:
        "首年于 「贸发网」上刊登广告的优质供应商。 阁下可参考有关供应商公司的详尽数据及资料,包括产品推介、厂房资料、业务资料、 研究与开发、原件创造/原创设计生产能力,以及厂房实况和产品展示短片等。",
      PREMIUM2:
        "连续第2年于 「贸发网」上刊登广告的优质供应商。 阁下可参考有关供应商公司的详尽数据及资料,包括产品推介、厂房资料、业务资料、 研究与开发、原件创造/原创设计生产能力,以及厂房实况和产品展示短片等。",
      PREMIUM3:
        "连续第3年于 「贸发网」上刊登广告的优质供应商。 阁下可参考有关供应商公司的详尽数据及资料,包括产品推介、厂房资料、业务资料、 研究与开发、原件创造/原创设计生产能力,以及厂房实况和产品展示短片等。",
      PREMIUM4:
        "连续第4年于 「贸发网」上刊登广告的优质供应商。 阁下可参考有关供应商公司的详尽数据及资料,包括产品推介、厂房资料、业务资料、 研究与开发、原件创造/原创设计生产能力,以及厂房实况和产品展示短片等。",
      PREMIUM5:
        "连续第5年于 「贸发网」上刊登广告的优质供应商。 阁下可参考有关供应商公司的详尽数据及资料,包括产品推介、厂房资料、业务资料、 研究与开发、原件创造/原创设计生产能力,以及厂房实况和产品展示短片等。",
      PREMIUM5P:
        "连续五年以上于 「贸发网」上刊登广告的优质供应商。 阁下可参考有关供应商公司的详尽数据及资料,包括产品推介、厂房资料、业务资料、 研究与开发、原件创造/原创设计生产能力,以及厂房实况和产品展示短片等。",
      SRI: "由第三方或贸发局提供的数据及资料,以给买家作识别。",
      ENV_COMPLIANCE: "厂房符合性检核",
      QUALITY_COMPLIANCE: "厂房符合性检核",
      SECURITY_COMPLIANCE: "厂房符合性检核",
      SOCIAL_COMPLIANCE: "厂房符合性检核",
      FREELISTING: "这供应商的数据资料未经查核及认证。",
    },
    topSearchTxt: "热门搜寻",
    searchTypeName: {
      all: "所有",
      "exhibitions-conferences": "会议及展览",
      suppliers: "产品及服务",
      "market-insights": "市场讯息",
      others: "其他",
    },
    successStories: {
      filter: {
        industries: "行业",
        regions: "国家 / 地区",
        themes: "专题",
      },
    },
    cookiesBar: {
      show: "true",
      desc: "This site uses cookies to offer you a better browsing experience. Find out more on <a href='#'>How we use cookies</a>",
      btn_accept: {
        txt: "Accept",
      },
      btn_refuse: {
        txt: "Refuse",
      },
    },
    feedbackPanel: {
      title: "您对全新首页有什么意见？",
      desc: '<p>香港贸发局网站换上了新装！我们期待<a href="%TELLUS%" target="_blank">你的意见</a>，让我们为你提供更佳的服务。在网站试行期间，你可随时浏览旧版的<a href="%DESKTOP%">网页版</a>或<a href="%MOBILE%">流动版</a>。</p>',
    },
    txt_publishDate: "发布日期:",
    txt_loading: "载入中...",
    txt_error: "系统错误: 不能获取内容。",
    factsheet: {
      date: "日期",
      countryRegion: "国家/地区",
      city: "城市",
      venue: "地点",
      address: "地址",
      locationMap: "位置图",
      format: "形式",
      industry: "行业",
      officialWebsiteUrl: "官方网站",
      topic: "主题",
      speaker: "演讲嘉宾",
      participant: "对象",
      participationFee: "费用",
      admissionFee: "入场费用",
      remark: "备注",
      eventConductedLanguages: "讲授语言",
      organiser: "主办机构",
      coOrganiser: "协办机构",
      supportingOrganisation: "支持机构",
      sponsor: "赞助商",
      informationForDownload: "资料下载",
      contactInformation: "联络资料",
      button: {
        enquire: "查询",
        exhibitorList: "参展商",
        downloadForm: "下载表格",
        applyBooth: "申请展位",
        preRegister: "预先登记",
      },
      eventDescription: "详细资料",
    },
    webcast: {
      industry: "行业",
      region: "地区",
      speaker: "演说者",
      keyword: "关键词",
      relatedLinks: "相关资料",
      relatedPrograms: "相关影片",
    },
    podcast: {
      industry: "行业",
      region: "地区",
      speaker: "演说者",
      keyword: "关键词",
      relatedLinks: "相关资料",
      relatedPrograms: "相关语音广播",
    },
    streamingLang: {
      hu: '匈牙利',
      jp: '日',
      es: '西班牙',
      en: '英',
      can: '粵',
      ru: '俄',
      de: '德',
      ko: '韩',
      it: '意大利',
      cs: '捷克',
      pt: '葡萄牙',
      pth: '普',
      ar: '阿拉伯',
      fr: '法'
    }
  },
};
export default translationsObject;
