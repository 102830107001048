import React, { Component } from "react";
import _, { trim } from "lodash";
import store from "../../store/";
import MetaHelmet from "../PageItem/MetaHelmet";
import HelmetStyle from '../../components/HelmetStyle';
import PageIntroContainer from "../../containers/PageIntroContainer";
import NoMatch from "../Layout/NoMatch";
import { fetchFactsheetPagesData } from "../../actions/";
import SanitizedHTML from "react-sanitized-html";
import config from "../../config";
import Loading from "../Loading/Loading"

export default class FactsheetPages extends Component {
    constructor(props) {
        super(props);
        let metaReady = false;
        store.dispatch(fetchFactsheetPagesData(props.match.params.pagename || ""));
        // if (props.factsheetPagesData.data.length <= 0) {
        //   store.dispatch(fetchFactsheetPagesData(props.match.params.pagename || ''));
        //   metaReady = false;
        // }
        this.state = {
            metaReady: metaReady,
        };
        this.pageMeta = {
            title: "",
            type: "",
            description: "",
            keywords: ""
        }
    }

    composeTealiumObj() {
        const { data = {} } = this.props.factsheetPagesData || {};
        const factsheetPagesData = ((data || {}).content || {}).content || {};
        const pageData = factsheetPagesData[this.props.i18n.locale] || {};
        const coreInfo = pageData.core_information || {};
        const tealiumObj = {
            site_subsection: config.tealium.factsheetPages.subsection,
            page_type: config.tealium.factsheetPages.pageType,
            page_name: coreInfo.event_name
        };
        // console.log(JSON.stringify(tealiumObj, null, 2));
        return tealiumObj;
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.allowCookiesStatusReducer.allowCookiesStatus === true &&
            this.props.allowCookiesStatusReducer.allowCookiesStatus !==
            prevProps.allowCookiesStatusReducer.allowCookiesStatus
        ) {
            setTimeout(() => {
                if (window.utag_data) {
                    console.log("Tealium Factsheet Pages load Sent:", new Date());
                    window.utag_data = this.composeTealiumObj();
                    window.enableTealium();
                }
            }, 1000);
        }


        if (this.props.match.params.pagename !== prevProps.match.params.pagename) {
            // handling: switch between static pages only
            this.setState(
                {
                    metaReady: false,
                },
                () => {
                    store.dispatch(
                        fetchFactsheetPagesData(this.props.match.params.pagename || "")
                    );
                    if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
                        setTimeout(() => {
                            if (window.utag) {
                                console.log(
                                    "Tealium Factsheet Pages Internal Link 2 Sent:",
                                    new Date()
                                );
                                window.utag.view(this.composeTealiumObj());
                            }
                        }, 1000);
                    }
                }
            );
        }
    }

    componentDidMount() {
        // Send data to WebTrends server on didMount if this is not landing page
        // if (this.state.metaReady) {
        //     console.log(
        //         "Factsheet Pages WT sent on DidMount Internal Link: ",
        //         new Date()
        //     );
        //     window.sendWebTrendsData();
        // }
        if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
            if (window.utag) {
                console.log("Tealium Factsheet Pages Internal Link Sent:", new Date());
                window.utag.view(this.composeTealiumObj());
            }
        }
    }

    // sendWebTrend = (l) => {
    //     if (l > 0 && !this.state.metaReady) {
    //         // ensure only send once
    //         this.setState(
    //             {
    //                 metaReady: true,
    //             },
    //             () => {
    //                 setTimeout(() => {
    //                     console.log(
    //                         "Factsheet Pages WT sent in render page load: ",
    //                         new Date()
    //                     );
    //                     window.sendWebTrendsData();
    //                 }, 500);
    //             }
    //         );
    //     }
    // };

    render() {
        const static_content =
            this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const { data = {} } = this.props.factsheetPagesData || {};
        const factsheetPagesData = ((data || {}).content || {}).content || {};
        const pageData = factsheetPagesData[this.props.i18n.locale] || {};
        const factsheetStyle = (factsheetPagesData.style || {}).css_style_block || "";
        const coreInfo = pageData.core_information || {};
        const eventDetails = pageData.event_details || {};
        const variousButtons = eventDetails.various_buttons || {};
        const { metadata = {} } = factsheetPagesData;
        const availableLanguages = metadata.availableLanguages && metadata.availableLanguages.split(",").map(v => v.trim());
        const isAvailableLanguage = availableLanguages && availableLanguages.includes(this.props.i18n.locale);

        this.pageMeta = {
            title: coreInfo.event_name,
            type: "website",
            description: ((eventDetails.optional_event_details_container || {})
                .event_details_container || {}
            ).event_details || "",
            keywords: coreInfo.keyword
        };

        const getEventConductedLanguage = () => {
            return (
                (
                    eventDetails.optional_event_details_container || {}
                ).event_conducted_language_container || {}
            ).event_conducted_language;
        };

        const getContactInformation = () => {
            return (
                (
                    (
                        (
                            eventDetails.optional_event_details_container || {}
                        ).contact_information_container || {}
                    ).core_contact_container || {}
                ).optional_contact_container || {}
            ).optional_contact_information;
        };

        const getOrganiser = (type) => {
            const containerName = `${type}_container`;
            const name = `${type}_name`;
            const url = `${type}_url`;

            if (_.isEmpty((eventDetails.organiser_information || {})[containerName])) return "";

            const organiserGroup = eventDetails.organiser_information;
            const container = organiserGroup[containerName];

            return !_.isEmpty(container[name]) ? renderURL(container[name], container[url])
                : (
                    Array.isArray(container) && container.map((item) => !_.isEmpty(item[name]) && renderURL(item[name], item[url]))
                        .reduce((prev, curr) => [prev, ", ", curr])
                );
        };

        const renderURL = (text, url = '') => {
            const isUrl = url.indexOf("http") === 0;

            if (text === "http://" || text === "https://") return null;

            return (isUrl && url !== "http://" && url !== "https://" ? <a href={url} target="_blank" rel="noopener noreferrer"><span>{text}</span></a> : text);
        }

        const table1Field = [
            {
                label: static_content.factsheet.date,
                value: coreInfo.display_date,
            },
            {
                label: static_content.factsheet.countryRegion,
                value: (coreInfo.display_region || "").split("|").join(", "),
            },
            {
                label: static_content.factsheet.city,
                value: (coreInfo.display_city || "").split("|").join(", "),
            },
            {
                label: static_content.factsheet.venue,
                value: coreInfo.venue,
            },
            {
                label: static_content.factsheet.address,
                value: (coreInfo.address_container || {}).address,
            },
            {
                label: static_content.factsheet.locationMap,
                value: (
                    (eventDetails.optional_event_details_container || {})
                        .location_map_container || {}
                ),
                isDownload: true,
            },
            {
                label: static_content.factsheet.format,
                value: (coreInfo.display_event_format || "").split("|").join(", "),
            },
        ];

        const table2Field = [
            {
                label: static_content.factsheet.eventDescription,
                value: (
                    (eventDetails.optional_event_details_container || {})
                        .event_details_container || {}
                ).event_details,
            },
        ];

        const table3Field = [
            {
                label: static_content.factsheet.officialWebsiteUrl,
                value: renderURL(coreInfo.official_website_url, coreInfo.official_website_url),
                isTemplate: true
            },
        ];

        const table4Field = [
            {
                label: static_content.factsheet.industry,
                value: (coreInfo.display_industry || "").split("|").join(", "),
            },
            {
                label: static_content.factsheet.topic,
                value: coreInfo.display_topic || "",
            },
            {
                label: static_content.factsheet.speaker,
                value: (
                    (eventDetails.optional_event_details_container || {})
                        .speaker_container || {}
                ).speaker,
            },
            {
                label: static_content.factsheet.participant,
                value: (
                    (eventDetails.optional_event_details_container || {})
                        .participants_container || {}
                ).participants,
            },
            {
                label: static_content.factsheet.participationFee,
                value: (
                    (eventDetails.optional_event_details_container || {})
                        .participation_fee_container || {}
                ).participation_fee,
            },
            {
                label: static_content.factsheet.admissionFee,
                value: (
                    (eventDetails.optional_event_details_container || {})
                        .admissionContainer || {}
                ).admissionFee,
            },
            {
                label: static_content.factsheet.remark,
                value: (eventDetails.optional_event_details_container || {}).remark,
            },
        ];

        const table5Field = [
            {
                label: static_content.factsheet.eventConductedLanguages,
                value: getEventConductedLanguage(),
            },
            {
                label: static_content.factsheet.organiser,
                value: getOrganiser("organiser"),
                isTemplate: true,
            },
            {
                label: static_content.factsheet.coOrganiser,
                value: getOrganiser("co_organiser"),
                isTemplate: true,
            },
            {
                label: static_content.factsheet.supportingOrganisation,
                value: getOrganiser("supporting_organisation"),
                isTemplate: true,
            },
            {
                label: static_content.factsheet.sponsor,
                value: getOrganiser("sponsor"),
                isTemplate: true,
            },
            {
                label: static_content.factsheet.informationForDownload,
                value: ((eventDetails.optional_event_details_container || {})
                    .information_for_download_container || {}),
                isDownload: true,
            },
            {
                label: static_content.factsheet.contactInformation,
                value: getContactInformation(),
            },
        ];

        const actionButtonList = [
            {
                label: static_content.factsheet.button.enquire,
                url: (variousButtons.enquire_container || {}).enquire_url,
                control: (variousButtons.enquire_container || {}).enquire_control,
            },
            {
                label: static_content.factsheet.button.preRegister,
                url: (variousButtons.pre_register_container || {}).pre_register_url,
                control: (variousButtons.pre_register_container || {}).pre_register_control,
            },
            {
                label: static_content.factsheet.button.exhibitorList,
                url: (variousButtons.exhibitor_list_container || {}).exhibitor_list_url,
                control: (variousButtons.exhibitor_list_container || {}).exhibitor_list_control,
            },
            {
                label: static_content.factsheet.button.downloadForm,
                url: (variousButtons.download_form_container || {}).path,
                control: (variousButtons.download_form_container || {}).download_form_control,
            },
            {
                label: static_content.factsheet.button.applyBooth,
                url: (variousButtons.apply_booth_container || {}).apply_booth_url,
                control: (variousButtons.apply_booth_container || {}).apply_booth_control,
            },
        ];

        const renderSanitizedHTML = (item) => {
            return (
                <SanitizedHTML
                    className="sec-intro__detail-desc"
                    allowedAttributes={config.allowedAttributes}
                    allowedTags={config.allowedTags}
                    html={item.value}
                />
            );
        }

        const renderSingleRow = (item, key) => {
         
            const isValidInformationDownloadUrl = item.isDownload && item.value.label && item.value.label.trim() !== "" && item.value.information_for_download_control === "yes" && item.value.path !== "http://" && item.value.path !== "https://";
            const isValidLocationMapUrl = item.isDownload && item.value.label && item.value.label.trim() !== "" && item.value.location_map_control === "yes" && item.value.path !== "http://" && item.value.path !== "https://";

            return (
                (!_.isEmpty(item.value) && (!item.isDownload || isValidInformationDownloadUrl || isValidLocationMapUrl)) &&
                <tr {...(_.isInteger(key) ? { key } : null)}>
                    <td className="greyrow">
                        <strong>{item.label}:</strong>
                    </td>
                    <td className="whiterow">
                        {
                            item.isTemplate ? item.value : (item.isDownload ? downloadElement(item.value) : renderSanitizedHTML(item))
                        }
                    </td>
                </tr>
            );
        };

        const renderMultipleRow = (list) => {
            const result = [];
            list.forEach((item, i) => {
                const row = renderSingleRow(item, i);
                if (row && !_.isEmpty(row)) {
                    result.push(row);
                }
            });
           
            return result;
        };

        const downloadElement = (item) => {
            const {
                information_for_download_control = 'no',
                location_map_control = 'no',
                path = '',
                label = '',
            } = item;
  
            const validURL = path.toLowerCase().trim().replace("","").replace("","") !== "";

            return (information_for_download_control === 'yes' || location_map_control === 'yes' ) && (validURL?(<a href={path} target="_blank">{label}</a>):( <>{label}</> ))  ;
        }

        const redirectToUrl = (url) => {
            window.location.href = url;
        };

        const renderActionButton = (list) => {
            return list.map((item, key) => {
                let isEnable = false;
                switch (item.control) {
                    case "Y":
                        isEnable = true;
                        break;
                    case "N":
                    default:
                        isEnable = false;
                        break;
                }
                return (
                    isEnable &&
                    <button
                        className="button button--ghost-orange"
                        key={key}
                        onClick={() => redirectToUrl(item.url)}
                    >
                        <span>
                            {item.label}
                        </span>
                    </button>
                );
            });
        };

        const table1DOM = renderMultipleRow(table1Field);
        const table2DOM = renderMultipleRow(table2Field);
        const table3DOM = renderMultipleRow(table3Field);
        const table4DOM = renderMultipleRow(table4Field);
        const table5DOM = renderMultipleRow(table5Field);
 
        return (
            <div>              
              {  (this.props.factsheetPagesData.isLoading  && <Loading />  )  }
                {!_.isEmpty(coreInfo) && isAvailableLanguage ? (
                    <>
                        <MetaHelmet metaData={this.pageMeta} locale={this.props.i18n.locale} />
                        <HelmetStyle stylesheet={factsheetStyle} />
                        <main>
                            <PageIntroContainer
                                title={coreInfo.window_title}
                                pageHead={coreInfo.window_title || ""}
                            />
                            <div>
                                <section className="sec-intro__detail">
                                    <div className="sec-intro__detail-wrapper">
                                        <h3 className="sec-intro__detail-title">{coreInfo.event_name}</h3>
                                        <div className="factsheetButtons">
                                            {renderActionButton(actionButtonList)}
                                        </div>

                                        {/* Table 1 */}
                                        {!_.isEmpty(table1DOM) ? (
                                            <div className="scrollableTable">
                                                <table className="factsheetTable">
                                                    <tbody>
                                                        {table1DOM}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}

                                        {/* Table 2 */}
                                        {!_.isEmpty(table2DOM) ? (
                                            <div className="scrollableTable">
                                                <table className="factsheetTable">
                                                    <tbody>
                                                        {table2DOM}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}

                                        {/* Table 3 */}
                                        {!_.isEmpty(table3DOM) ? (
                                            <div className="scrollableTable">
                                                <table className="factsheetTable">
                                                    <tbody>
                                                        {table3DOM}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}

                                        {/* Table 4 */}
                                        {!_.isEmpty(table4DOM) ? (
                                            <div className="scrollableTable">
                                                <table className="factsheetTable">
                                                    <tbody>
                                                        {table4DOM}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}

                                        {/* Table 5 */}
                                        {!_.isEmpty(table5DOM) ? (
                                            <div className="scrollableTable">
                                                <table className="factsheetTable">
                                                    <tbody>
                                                        {table5DOM}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : null}

                                    </div>
                                </section>
                            </div>
                        </main>
                    </>
                ) : (
                    (this.props.factsheetPagesData.error || _.isEmpty(data) || !isAvailableLanguage) && !this.props.factsheetPagesData.isLoading && <NoMatch />
                )}
            </div>
        );
    }
}
