import * as types from "../../constants/actionTypes";
import config from "../../config";
import { axiosAdapter } from "../../util/adapters";
import moment, { locales } from "moment-timezone"
var sprintf = require("sprintf-js").sprintf;

function requestFactsheetPagesData() {
  return { type: types.REQ_FACTSHEETPAGES_DATA };
}

function receiveFactsheetPagesData(json) {
  return {
    type: types.RECV_FACTSHEETPAGES_DATA,
    data: json,
  };
}

function receiveFactsheetPagesError(json) {
  return {
    type: types.RECV_FACTSHEETPAGES_ERROR,
    data: json,
  };
}

export function fetchFactsheetPagesData(pagename) {


  //check pagename starts with FS- or not, if 
  const isNewFactsheet = pagename.toUpperCase().startsWith("FS-");

  return function (dispatch) {
    dispatch(requestFactsheetPagesData());
    const dateTime = new Date().getTime();

    if (!isNewFactsheet) {
      //isNewFactsheet = false , original flow
      let apiLink = sprintf(config.api_factsheet, pagename);

      apiLink = apiLink.replace(/\[DATETIME\]/g, `${moment(dateTime).startOf('hour').toDate().getTime()}`);
      return axiosAdapter({
        url: apiLink,
      })
        .then(function (response) {
          dispatch(
            receiveFactsheetPagesData(
              response.data.result.assets[0]
            )
          );
        })
        .catch(function (response) {
          dispatch(receiveFactsheetPagesError(response.data || response.message));
          //dispatch(pushState(null,'/error'));
        });

    } else {
      //isNewFactsheet = true , new flow
      let apiLink = sprintf(config.api_factsheet_V2, pagename);
      
      apiLink = apiLink.replace(/\[DATETIME\]/g, `${moment(dateTime).startOf('hour').toDate().getTime()}`);
      return axiosAdapter({
        url: apiLink,
      })
        .then(function (response) {
          //get basic event Information from EMS public API
          const emsID = response.data.result.assets[0].metadata.eventId;
          axiosAdapter({
            url: config.api_ems_event,
            params: { "eventId": emsID },
            headers: { "accept": "application/json" }
          }).then(function (emsRes) {

            if (emsRes.data) {
              //handle event with open / completed status only
              var done = false;
              emsRes.data.results.map(function (emsElement) {

                if (!done) {
                  const locale = emsElement.content.locale;
                  const localeSymbol = locale.replace("zh-hant", "tc").replace("zh-hans", "sc");
                  const isCurrentLocale = (window.location.href.indexOf("/" + localeSymbol + "/") > -1);
                  const validStatus = ["completed", "open"].includes(emsElement.content.event_status);

                  if (isCurrentLocale) {
                    done = true;

                    if (validStatus) {

                      //massaged the data of ems api and factsheet api
                      const source = response.data.result.assets[0];

                      if (source !== undefined && source.content.content[localeSymbol] !== undefined) {
                        const targetLocale = source.content.content[localeSymbol];
                        const calendar = emsElement.content["event_calendar"][0];
                        targetLocale["core_information"] = {};
                        targetLocale["event_details"] = {};

                        //massage core_inforamtion
                        targetLocale["core_information"]["event_name"] = emsElement.content["event_name"];
                        targetLocale["core_information"]["window_title"] = emsElement.content["event_name"];
                        targetLocale["core_information"]["sub_title"] = emsElement.content["event_name"];
                        targetLocale["core_information"]["address_container"] = targetLocale["address_container"];
                        targetLocale["core_information"]["article_at_glance"] = emsElement.content["event_description"];

                        if (targetLocale["optional_event_details_container"]["display_date"].trim() !== "") {
                          //display_date is provided in the Factsheet DCR
                          targetLocale["core_information"]["display_date"] = targetLocale["optional_event_details_container"]["display_date"];
                        } else {
                          //display_date is not provided in the Factsheet DCR
                          const startDateTimeStamp = new Date(moment(emsElement.content["event_period_start_date"]).startOf('hour').toDate().getTime());
                          const endDateTimeStamp = new Date(moment(emsElement.content["event_period_end_date"]).startOf('hour').toDate().getTime());
                          const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                          const toStr = { "en": " to ", "tc": " 至 ", "sc": " 至 " };
                          let startDate = "", endDate = "";

                          switch (localeSymbol) {
                            case "en":
                              startDate = startDateTimeStamp.getDate() + " " + month[startDateTimeStamp.getMonth()] + " " + startDateTimeStamp.getFullYear();
                              endDate = endDateTimeStamp.getDate() + " " + month[endDateTimeStamp.getMonth()] + " " + endDateTimeStamp.getFullYear();
                              break;
                            case "tc":
                            case "sc":
                              startDate = startDateTimeStamp.getFullYear() + "年" + (startDateTimeStamp.getMonth() * 1 + 1) + "月" + startDateTimeStamp.getDate() + "日";
                              endDate = endDateTimeStamp.getFullYear() + "年" + (endDateTimeStamp.getMonth() * 1 + 1) + "月" + endDateTimeStamp.getDate() + "日";
                              break;
                          }

                          if (startDate === endDate) {
                            targetLocale["core_information"]["display_date"] = startDate
                          } else {

                            targetLocale["core_information"]["display_date"] = startDate + toStr[localeSymbol] + endDate
                          }
                        }
                        const cityArr = [];
                        const regionArr = [];
                        const countryArr = [];
                        const provinceArr = [];

                        emsElement.content["event_location"]["region"].forEach(element => {
                          const { NAME } = element;
                          regionArr.push(NAME)
                        });


                        /*
                        
                        //omitted below parts due to EMS public API result event_location structure changed
                        emsElement.content["event_location"]["country"].forEach(element => {
                          const { NAME } = element;
                          countryArr.push(NAME)
                        });

                        emsElement.content["event_location"]["province"].forEach(element => {
                          const { NAME } = element;
                          provinceArr.push(NAME)
                        });

                        emsElement.content["event_location"]["city"].forEach(element => {
                          const { NAME } = element;
                          cityArr.push(NAME)
                        });

                        */

                        targetLocale["core_information"]["display_city"] = cityArr.join("|");
                        targetLocale["core_information"]["display_region"] = [...new Set(regionArr)].join("|");
                        targetLocale["core_information"]["display_location"] = [...new Set([...provinceArr, ...regionArr, ...countryArr])].join("|");
                        targetLocale["core_information"]["display_gbaCity"] = calendar["gba_city"].join("|");

                        const formatArr = [];
                        emsElement.content["event_format"].forEach(element => {
                          const { NAME } = element;
                          formatArr.push(NAME)
                        });
                        targetLocale["core_information"]["display_event_format"] = formatArr.join("|");

                        const industryArr = [];
                        emsElement.content["industry"].forEach(element => {
                          const { NAME } = element;
                          industryArr.push(NAME)
                        });

                        targetLocale["core_information"]["display_industry"] = industryArr.join("|");

                        targetLocale["core_information"]["venue"] = emsElement.content["event_venue"] === "Other" ? (emsElement.content["event_venue_other"]) : (emsElement.content["event_venue"]);
                        targetLocale["core_information"]["keyword"] = calendar["event_keywords"];
                        /* targetLocale["core_information"]["official_website_url"] = calendar["official_website_url"];   // removed as requested in EMS-3399  */
                        targetLocale["core_information"]["official_website_url"] = "";
                        targetLocale["core_information"]["link_choice"] = "Y";
                        targetLocale["core_information"]["eventVideoUrl"] = "";

                        const topicArr = [];
                        emsElement.content["topic"].forEach(element => {
                          const { NAME } = element;
                          NAME !== "" && topicArr.push(NAME)
                        });
                        targetLocale["core_information"]["display_topic"] = topicArr.join(", ");

                        //massage event_details
                        targetLocale["event_details"]["organiser_information"] = targetLocale["organiser_information"];
                        targetLocale["event_details"]["optional_event_details_container"] = targetLocale["optional_event_details_container"];

                        const buttonConfig = {};
                        const applyBothObj = {}, exhibitorListObj = {}, eventCalendarObj = {}, preRegObj = {}, enquirieObj = {}, downloadFormObj = {};
                        buttonConfig["public_visitors_container"] = { "public_visitors_link": "" };
                        buttonConfig["fair_catalog_container"] = { "fair_catalog_year": "", "fair_catalog_link": "", "fair_catalog_ind": "N", "fair_catalog_month": "" };

                        //config applyBothObj
                        applyBothObj["apply_booth_control"] = calendar["apply_booth_available"] == true ? "Y" : "N";
                        applyBothObj["apply_booth_url"] = calendar["apply_booth_url"];

                        //config exhibitorListObj
                        exhibitorListObj["exhibitor_list_url"] = calendar["exhibitor_url"];
                        exhibitorListObj["exhibitor_list_control"] = calendar["exhibitor_available"] == true ? "Y" : "N";

                        //config eventCalendarObj
                        eventCalendarObj["event_calendar_link"] = calendar["event_calendar_link"];

                        //config preRegObj
                        preRegObj["pre_reg_start_date"] = "";
                        preRegObj["pre_reg_end_date"] = "";
                        preRegObj["pre_register_source"] = "";
                        preRegObj["pre_register_control"] = calendar["pre_register_available"] == true ? "Y" : "N";
                        preRegObj["pre_register_url"] = calendar["pre_register_url"];

                        //config enquirieObj
                        enquirieObj["enquire_control"] = calendar["enquire_available"] == true ? "Y" : "N";
                        enquirieObj["enquire_url"] = calendar["enquire_url"];
                        enquirieObj["enquire_url_value"] = "";

                        //config downloadFormObj
                        downloadFormObj["path.clear"] = "";
                        downloadFormObj["path"] = calendar["download_file_url"];
                        downloadFormObj["download_form_control"] = calendar["download_file_available"] == true ? "Y" : "N";
                        downloadFormObj["path.upload"] = "";

                        buttonConfig["apply_booth_container"] = applyBothObj;
                        buttonConfig["exhibitor_list_container"] = exhibitorListObj;
                        buttonConfig["event_calendar_container"] = eventCalendarObj;
                        buttonConfig["pre_register_container"] = preRegObj;
                        buttonConfig["enquire_container"] = enquirieObj;
                        buttonConfig["download_form_container"] = downloadFormObj;
                        targetLocale["event_details"]["various_buttons"] = buttonConfig;

                        //tidy output
                        delete targetLocale["address_container"];
                        delete targetLocale["organiser_information"];
                        delete targetLocale["optional_event_details_container"];
                        delete targetLocale["display_date"];
                      }
                      dispatch(
                        receiveFactsheetPagesData(
                          source
                        )
                      )


                    } else {
                      dispatch(receiveFactsheetPagesError("event status invalid"));
                    }
                  }


                }
              });

              if (!done) {
                dispatch(receiveFactsheetPagesError("event not in en/tc/sc"));
              }
            }


          }).catch(function (emsErr) {
            dispatch(receiveFactsheetPagesError(emsErr.data || emsErr.message));
          })

        })
        .catch(function (response) {
          dispatch(receiveFactsheetPagesError(response.data || response.message));
          //dispatch(pushState(null,'/error'));
        });

    }



  };
}
