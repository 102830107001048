import React, { Component } from "react";
import store from "../../store/";
import config from '../../config';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { fetchSuccessStoriesFilters } from '../../actions';

class SuccessStoriesFilters extends Component {
	constructor(props) {
		super(props);
		store.dispatch(fetchSuccessStoriesFilters({"language": config.api_lang_map[this.props.i18n.locale]}));
	}

	render() {
		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};

		const props = this.props;
		const getValFunc = this.props.getValFunc;
		const { data = {}, isLoading = false} = this.props.pageSSFiltersReducer || {};
		
		const story_filter = Object.keys(data).map(function (v) {
			let defaultValue = [];
			
			if (props[v] !== '') {
				defaultValue = (data[v]).filter(item => item.value === props[v]);
			}
				let title = props[v] !== '' ? defaultValue[0].text : static_content.successStories.filter[v];
				
				
				return (
					(v!=='themes' && v!=='regions') && (<div key={v} className="story-filter-cat-container">
						<div className="filter-cat__title">{title}</div>
						<select value={props[v] !== '' ? defaultValue[0].value || '' : ''} className="story-filter-condList" onChange={(e) => getValFunc(e, v)} aria-label={"Success Story Filter For "+title}>
							<option className="filter-condList__cond" value="">{static_content.successStories.filter[v]}</option>
							{ Object.keys(data[v]).map((i, value) => <option key={i} className="filter-condList__cond" value={data[v][i].value}>{data[v][i].text}</option>)}
						</select>
					</div>)
				)
			});


		return (
			<div className="story-filter">
				<div className="story-filter__title">{static_content.events.txt_filterBy}</div>
				{
					isLoading ? 
					<span>{static_content.txt_loading}</span>
					: story_filter
				}
      </div>
		);
	}
}

const mapStateToProps = state => ({
	i18n: state.i18n,
	pageSSFiltersReducer: state.pageSSFiltersReducer
});

export default withRouter(connect(mapStateToProps, null, null, {
	pure: true
})(SuccessStoriesFilters));